
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

// Composable
import useFTLBid from '@/modules/FTLBidModule/composable/useFTLBid'

// Components
import SButton from '@/common/components/SButton/index.vue'
import SendDateTimePeriod from '@/modules/CreateOrder/components/SendDateTimePeriod/index.vue'
import CargoCharacteristics from '@/modules/FTLBidModule/components/CargoCharacteristics/index.vue'
import CompletedDate from '@/modules/FTLBidModule/components/CompletedDate/index.vue'
import NewWaypointsForm from '@/modules/FTLBidModule/components/NewWaypointsForm/index.vue'

const FTL_TYPES = [
    { name: 'Междугородняя доставка', value: 0 },
    { name: 'Международная доставка', value: 1 },
    { name: 'Внутригородская доставка', value: 2 },
]

export default defineComponent({
    components: {
        's-button': SButton,
        'send-date-time-period': SendDateTimePeriod,
        'cargo-characteristics': CargoCharacteristics,
        'completed-date': CompletedDate,
        'new-waypoints-form': NewWaypointsForm,
    },
    setup() {
        const {
            state,
            additional_services_state,
            progress,
            setDateAndTime,
            resetDateAndTime,
            setCharacteristics,
            resetCharacteristics,
            setWaypoints,
            createBID,
            onTypeChange,
            // setAdditionalServices,
            // resetAdditionalServices,
        } = useFTLBid()

        const router = useRouter()

        const goToBid = () => {
            router.push('/history')
        }

        return {
            state,
            additional_services_state,
            progress,
            FTL_TYPES,
            setDateAndTime,
            resetDateAndTime,
            setCharacteristics,
            resetCharacteristics,
            setWaypoints,
            createBID,
            onTypeChange,
            goToBid,
        }
    },
})


import { computed, ComputedRef, defineComponent, reactive, Ref, ref } from 'vue'

import SCalendar from '@/common/components/SCalendar/index.vue'
import STime from '@/common/components/STime/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import iClose from '@/assets/icons/Close.svg'

import { FTLCalendarT } from '@/core/types/FTL.types'
import { LTLCalendarT } from '@/core/types/LTL.types'
import { OptionT } from '@/core/types/common.types'

import { useCalendar } from '@/common/composable/useCalendar'
import { isFTL, isLTL } from '@/modules/CreateOrder/composable/useCreateOrder'

import { SendersRealisation } from '@/core/realisations/Senders.realisation'

export default defineComponent({
    components: {
        's-time': STime,
        's-calendar': SCalendar,
        's-button': SButton,
        'icon-close': iClose,
    },
    props: {
        isFTL: {
            type: Boolean,
            default: true,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
        opened: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm'],
    setup(_, { emit }) {
        const sendTimeElement: any = ref(null)

        const { getDayName, getMonthName } = useCalendar()
        const senders = new SendersRealisation()

        const state = reactive({
            is_loading: false,
            is_can_submit: true,
        })

        const FTLState: FTLCalendarT = reactive({
            send_date: null,
            send_time: null,
        })

        const LTLState: LTLCalendarT = reactive({
            send_date: null,
            send_period: null,
        })

        const activeState: ComputedRef<FTLCalendarT | LTLCalendarT> = computed(() => {
            if (isFTL.value) return FTLState
            return LTLState
        })

        const isDateSelected = computed(() => Boolean(activeState.value.send_date))

        const resetCalendar = () => {
            if (isFTL.value) {
                activeState.value.send_date = null
                if ('send_time' in activeState.value) activeState.value.send_time = null
            }

            if (isLTL.value) {
                activeState.value.send_date = null
                if ('send_period' in activeState.value) activeState.value.send_period = null
            }
        }

        const setTime = (send_time: string) => {
            if ('send_time' in activeState.value) activeState.value.send_time = send_time
        }

        const setDate = (send_date: Date) => {
            activeState.value.send_date = send_date
        }

        const setPeriod = (send_period: OptionT<string>) => {
            if ('send_period' in activeState.value) activeState.value.send_period = send_period
        }

        const emitConfirm = () => {
            const payload: any = {
                send_date: activeState.value.send_date,
            }

            if ('send_time' in activeState.value) payload.send_time = activeState.value.send_time
            if ('send_period' in activeState.value)
                payload.send_period = activeState.value.send_period

            emit('confirm', payload)
        }

        const onUpdateDate = async (date: Date) => {
            let date_string = JSON.stringify(date)
            let date_payload = date_string ? date_string.replace('"', '').split('T')[0] : null

            try {
                if (!date_payload) return

                sendTimeElement.value?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })

                state.is_loading = true
                const response = await senders.checkCompanySchedule(date_payload)

                state.is_can_submit = response.data.isOpen
            } catch (error) {
                console.error(error)
            } finally {
                state.is_loading = false
            }
        }

        const onUpdatePeriod = (period: OptionT<number>) => {
            /* @ts-ignore */
            activeState.value.send_period = period
        }

        return {
            state,
            activeState,
            isDateSelected,
            resetCalendar,
            setTime,
            setDate,
            setPeriod,
            getDayName,
            getMonthName,
            emitConfirm,
            onUpdateDate,
            onUpdatePeriod,
            sendTimeElement,
        }
    },
})


import { defineComponent, reactive, computed, watch } from 'vue'
import draggable from 'vuedraggable'

// Components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'

// Module components
import PointInPanel from '@/modules/CreateOrder/components/PointInPanel/index.vue'
import PanelsLoader from '@/modules/CreateOrder/components/PanelsLoader/index.vue'
import ReceiverCreate from '@/modules/CreateOrder/components/NewReceiverCreate/index.vue'
import SenderCreate from '@/modules/CreateOrder/components/SenderCreate/index.vue'
import AdditionalServices from '@/modules/CreateOrder/components/AdditionalServices/index.vue'

// Icons
import iSearch from '@/assets/icons/Search.svg'
import iBigPlus from '@/assets/icons/BigPlus.svg'
import iDraggable from '@/assets/icons/Draggable.svg'

// Types
import { ReceiverT, SenderT } from '@/core/types/common.types'
import { ReceiverCreatePayload, SenderCreatePayload } from '@/core/types/Order.types'
import { NewWaypointT } from '@/core/types/FTL.types'

// Realisations
import { SendersRealisation } from '@/core/realisations/Senders.realisation'
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'

// Composable
import { useDebounce } from 'vue-composable'
import {
    isSenderActionsOpened,
    isReceiverActionsOpened,
    useMembersActions,
} from '@/modules/CreateOrder/composable/useMembersActions'
import useNotifications from '@/common/composable/useNotifications'
import useFTLBid from '../../composable/useFTLBid'

export default defineComponent({
    components: {
        draggable,
        's-input': SInput,
        's-button': SButton,
        's-modal': SModal,
        'point-in-panel': PointInPanel,
        'panels-loader': PanelsLoader,
        'sender-create': SenderCreate,
        'receiver-create': ReceiverCreate,
        'icon-search': iSearch,
        'icon-big-plus': iBigPlus,
        'icon-draggable': iDraggable,
        AdditionalServices,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        opened: {
            type: Boolean,
            default: false,
        },
        completed: {
            type: Boolean,
            default: false,
        },
        ftlType: {
            type: Number,
            default: null,
        },
    },
    emits: ['submit'],
    setup(props, { emit }) {
        const notifications = useNotifications()

        const senders = new SendersRealisation()
        const receivers = new ReceiversRealisation()

        const { setAdditionalServices, resetAdditionalServices } = useFTLBid()

        const state = reactive({
            selected_templates: [] as any[],
            search_query: null as string | null,
            drag: false,
        })

        const data_state = reactive({
            senders: {
                is_loading: false,
                is_failed: false,
                list: [] as SenderT[],
                pagination: {
                    current_page: 1,
                    total_pages: 1,
                },
            },
            receivers: {
                is_loading: false,
                is_failed: false,
                list: [] as ReceiverT[],
                pagination: {
                    current_page: 1,
                    total_pages: 1,
                },
            },
        })

        const isSenderSelected = computed(() => state.selected_templates.length > 0)
        const isInsideCity = computed(() => props.ftlType == 2)

        const loadSenders = async (page = 1) => {
            data_state.senders.pagination.current_page = page

            try {
                data_state.senders.is_loading = true
                const response = await senders.getAll(page)
                data_state.senders.list = response.data.senders

                data_state.senders.pagination.total_pages = response.data.total_pages

                data_state.senders.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.senders.is_failed = true
            } finally {
                data_state.senders.is_loading = false
            }
        }

        const resetSenders = () => {
            data_state.senders.is_loading = false
            data_state.senders.is_failed = false
            data_state.senders.list = []
            data_state.senders.pagination.current_page = 1
            data_state.senders.pagination.total_pages = 1
        }

        const loadMoreSenders = async (page: number) => {
            if (page > data_state.senders.pagination.total_pages) return
            data_state.senders.pagination.current_page = page

            try {
                data_state.senders.is_loading = true

                const response = await senders.getAll(page)
                data_state.senders.list = data_state.senders.list.concat(response.data.senders)
                data_state.senders.pagination.total_pages = response.data.total_pages

                data_state.senders.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.senders.is_failed = true
            } finally {
                data_state.senders.is_loading = false
            }
        }

        const loadReceivers = async (page = 1) => {
            data_state.receivers.pagination.current_page = page

            try {
                data_state.receivers.is_loading = true

                if (isInsideCity.value) {
                    let city_id = state.selected_templates[0].city_id

                    const response = await receivers.getAllFTL(page, city_id)
                    data_state.receivers.list = response.data.receivers
                    data_state.receivers.pagination.total_pages = response.data.total_pages
                } else {
                    const response = await receivers.getAllFTL(page)
                    data_state.receivers.list = response.data.receivers
                    data_state.receivers.pagination.total_pages = response.data.total_pages
                }

                data_state.receivers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.receivers.is_failed = true
            } finally {
                data_state.receivers.is_loading = false
            }
        }

        const resetReceivers = () => {
            data_state.receivers.is_loading = false
            data_state.receivers.is_failed = false
            data_state.receivers.list = []
            data_state.receivers.pagination.current_page = 1
            data_state.receivers.pagination.total_pages = 1
        }

        const loadMoreReceivers = async (page: number) => {
            if (page > data_state.receivers.pagination.total_pages) return
            data_state.receivers.pagination.current_page = page

            try {
                data_state.receivers.is_loading = true

                if (isInsideCity.value) {
                    let city_id = state.selected_templates[0].city_id

                    const response = await receivers.getAllFTL(page, city_id)
                    data_state.receivers.list = data_state.receivers.list.concat(
                        response.data.receivers
                    )
                    data_state.receivers.pagination.total_pages = response.data.total_pages
                } else {
                    const response = await receivers.getAllFTL(page)
                    data_state.receivers.list = data_state.receivers.list.concat(
                        response.data.receivers
                    )
                    data_state.receivers.pagination.total_pages = response.data.total_pages
                }

                data_state.receivers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.receivers.is_failed = true
            } finally {
                data_state.receivers.is_loading = false
            }
        }

        const searchSender = async () => {
            data_state.senders.pagination.current_page = 1
            data_state.senders.pagination.total_pages = 1
            data_state.senders.list = []

            if (!state.search_query || state.search_query === '') {
                await loadSenders(1)
                return
            }

            try {
                data_state.senders.is_loading = true

                const response = await senders.search(state.search_query)
                data_state.senders.list = response.data

                data_state.senders.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.senders.is_failed = true
            } finally {
                data_state.senders.is_loading = false
            }
        }

        const deleteSender = async (id: number) => {
            try {
                await senders.delete(id)

                const index = data_state.senders.list.findIndex((sender) => sender.id === id)
                if (index >= 0) {
                    data_state.senders.list.splice(index, 1)
                }
            } catch (error) {
                console.error(error)
            }
        }

        const searchReceiver = async () => {
            data_state.receivers.pagination.current_page = 1
            data_state.receivers.pagination.total_pages = 1
            data_state.receivers.list = []

            if (!state.search_query || state.search_query === '') {
                loadReceivers(1)
                return
            }

            try {
                data_state.receivers.is_loading = true

                if (!isInsideCity.value) {
                    const response = await receivers.searchLTL(
                        state.search_query,
                        senderCityId.value
                    )
                    data_state.receivers.list = response.data.receivers
                }

                if (isInsideCity.value) {
                    const response = await receivers.search(state.search_query, senderCityId.value)
                    data_state.receivers.list = response.data.receivers
                }

                data_state.receivers.is_failed = false
            } catch (error) {
                console.error(error)
                data_state.receivers.is_failed = true
            } finally {
                data_state.receivers.is_loading = false
            }
        }

        const deleteReceiver = async (id: number) => {
            try {
                await receivers.delete(id)

                const index = data_state.receivers.list.findIndex((receiver) => receiver.id === id)
                if (index >= 0) {
                    data_state.receivers.list.splice(index, 1)
                }
            } catch (error) {
                console.error(error)
            }
        }

        const onLoadMoreTemplates = () => {
            if (isSenderSelected.value) {
                loadMoreReceivers(data_state.receivers.pagination.current_page + 1)
            } else {
                loadMoreSenders(data_state.senders.pagination.current_page + 1)
            }
        }

        const onTemplateSearch = useDebounce(() => {
            if (isSenderSelected.value) {
                searchReceiver()
            } else {
                searchSender()
            }
        }, 800)

        watch(
            () => props.opened,
            (opened) => {
                if (opened) {
                    if (isSenderSelected.value) {
                        loadReceivers(1)
                    } else {
                        loadSenders(1)
                    }
                }
            }
        )

        // On ftl type changed
        watch(
            () => props.ftlType,
            (ftl_type) => {
                if (props.opened && !props.completed) {
                    state.selected_templates = []
                    state.search_query = null
                    state.drag = false

                    resetSenders()
                    resetReceivers()

                    loadSenders(1)
                }
            }
        )

        const onTemplateSelect = (template: SenderT | ReceiverT) => {
            if (!template.full_address || !template.latitude || !template.longitude) {
                onStartEditTemplate(template)

                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'Пожалуйста, укажите адрес',
                })

                return
            }

            if (!template.phone) {
                onStartEditTemplate(template)

                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'Пожалуйста, укажите номер телефона',
                })

                return
            }

            if (state.selected_templates.length === 0) {
                state.selected_templates.push(template)

                resetSenders()
                loadReceivers(1)

                state.search_query = null
                return
            }

            state.selected_templates.push(template)
            state.search_query = null
        }

        const onSelectedTemplateDelete = (template_index: number) => {
            if (template_index === 0) {
                const answer = confirm(
                    'При удалении отправного пункта остальные пункты будут сброшены, продолжить?'
                )
                if (!answer) return

                state.selected_templates = []

                resetSenders()
                resetReceivers()

                loadSenders(1)
                return
            }

            const answer = confirm('Вы действительно хотите удалить выбранный шаблон?')
            if (!answer) return
            state.selected_templates.splice(template_index, 1)
        }

        const onTemplateDelete = (id: number) => {
            if (isSenderSelected.value) {
                deleteReceiver(id)
            } else {
                deleteSender(id)
            }
        }

        const onTemplateDeleted = () => {
            if (isSenderSelected.value) {
                loadReceivers(data_state.receivers.pagination.current_page)
            } else {
                loadSenders(data_state.senders.pagination.current_page)
            }
        }

        const {
            closeSenderActions,
            startCreateSender,
            startEditSender,
            createSender,
            updateSender,
            closeReceiverActions,
            startCreateReceiver,
            startEditReceiver,
            createReceiver,
            updateReceiver,
        } = useMembersActions()

        const onStartCreateTemplate = () => {
            if (!isSenderSelected.value) {
                startCreateSender()
            } else {
                startCreateReceiver()
            }
        }

        const onStartEditTemplate = (member: SenderT | ReceiverT) => {
            if (!isSenderSelected.value) {
                startEditSender(member as SenderT)
            } else {
                startEditReceiver(member as ReceiverT)
            }
        }

        const onTemplateCreate = async (payload: SenderCreatePayload | ReceiverCreatePayload) => {
            try {
                if (!isSenderSelected.value) {
                    await createSender(payload)
                    closeSenderActions()
                    loadSenders(1)
                } else {
                    await createReceiver(payload as ReceiverCreatePayload)
                    closeReceiverActions()
                    loadReceivers(1)
                }
            } catch (error) {
                console.error(error)
            }
        }

        const onTemplateUpdate = async (payload: SenderCreatePayload | ReceiverCreatePayload) => {
            if (!payload.id) return

            try {
                if (!isSenderSelected.value) {
                    await updateSender(payload.id, payload)
                    closeSenderActions()
                    loadSenders(1)
                } else {
                    await updateReceiver(payload.id, payload as ReceiverCreatePayload)
                    closeReceiverActions()
                    loadReceivers(1)
                }
            } catch (error) {
                console.error(error)
            }
        }

        const onDragEnd = () => {
            state.drag = false
        }

        const onDragMove = (event: any) => {
            const context = event.draggedContext

            if (context.index === 0 || context.futureIndex === 0) {
                return false
            }
        }

        const onSubmit = () => {
            if (state.selected_templates.length < 2) {
                notifications.failure({
                    group: 'create-module',
                    type: 'error',
                    title: 'Для заверешния выбора пунктов укажите отправной и конечный пункты',
                })

                return
            }

            let waypoints: NewWaypointT[] = state.selected_templates.map((waypoint, index) => {
                return {
                    template_id: waypoint.id,
                    city_id: waypoint.city_id,
                    is_endpoint: index === state.selected_templates.length - 1 ? 1 : 0,
                    number: index,
                    additional_service: waypoint.additional_service,
                }
            })

            emit('submit', [...waypoints])
        }

        const isFailedState = computed(
            () => data_state.senders.is_failed || data_state.receivers.is_failed
        )
        const isLoadingState = computed(
            () => data_state.senders.is_loading || data_state.receivers.is_loading
        )

        const isEmptyState = computed(() => {
            if (
                !isSenderSelected.value &&
                !data_state.senders.is_loading &&
                data_state.senders.list.length === 0
            )
                return true
            if (
                isSenderSelected.value &&
                !data_state.receivers.is_loading &&
                data_state.receivers.list.length === 0
            )
                return true

            return false
        })

        const checkIfTemplateSelected = (template_id: number) => {
            const maybe_finded = state.selected_templates.find(
                (selected_template) => selected_template.id === template_id
            )
            return Boolean(maybe_finded)
        }

        const senderCityId = computed(() => {
            if (state.selected_templates.length === 0) return null
            return state.selected_templates[0].city_id
        })

        const receiverCityId = computed(() => {
            if (state.selected_templates.length === 0) return null
            if (!isSenderSelected.value) return null
            if (!isInsideCity.value) return null

            return state.selected_templates[0].city_id
        })

        return {
            isSenderActionsOpened,
            isReceiverActionsOpened,
            //
            state,
            isSenderSelected,
            isInsideCity,
            data_state,
            //
            closeSenderActions,
            closeReceiverActions,
            onTemplateSelect,
            onSelectedTemplateDelete,
            onTemplateDelete,
            onTemplateDeleted,
            onTemplateCreate,
            onTemplateUpdate,
            onTemplateSearch,
            onLoadMoreTemplates,
            onStartCreateTemplate,
            onStartEditTemplate,
            //
            isFailedState,
            isLoadingState,
            isEmptyState,
            //
            checkIfTemplateSelected,
            senderCityId,
            receiverCityId,
            //
            onDragEnd,
            onDragMove,
            //
            onSubmit,
            setAdditionalServices,
            resetAdditionalServices,
        }
    },
})

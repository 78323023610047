
import { defineAsyncComponent, defineComponent, PropType, reactive, ref, unref } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

import PointInPanel from '@/modules/CreateOrder/components/PointInPanel/index.vue'
import PanelsLoader from '@/modules/CreateOrder/components/PanelsLoader/index.vue'

import SAsyncModalLoader from '@/common/components/SAsyncModalLoader/index.vue'

import SModal from '@/common/components/SModal/index.vue'

const SenderCreate = defineAsyncComponent({
    loader: () => import('@/modules/CreateOrder/components/SenderCreate/index.vue'),
    loadingComponent: SAsyncModalLoader,
})

import iSearch from '@/assets/icons/Search.svg'
import iBigPlus from '@/assets/icons/BigPlus.svg'

import { SenderT } from '@/core/types/common.types'

import {
    isSenderActionsOpened,
    useMembersActions,
} from '@/modules/CreateOrder/composable/useMembersActions'

import { SenderCreatePayload } from '@/core/types/Order.types'
import { useDebounce } from 'vue-composable'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        'point-in-panel': PointInPanel,
        'panels-loader': PanelsLoader,
        's-modal': SModal,
        'sender-create': SenderCreate,
        'icon-search': iSearch,
        'icon-big-plus': iBigPlus,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        senders: {
            type: Array as PropType<SenderT[]>,
            default: () => [],
        },
        current_page: {
            type: Number,
            default: 1,
        },
        total_pages: {
            type: Number,
            default: 1,
        },
        isSearch: {
            type: Boolean,
            default: false,
        },
        isFTL: {
            type: Boolean,
            default: false,
        },
        isLTL: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['refresh', 'loadmore', 'select', 'search', 'delete'],
    setup(_, { emit }) {
        const notifications = useNotifications()

        const search_query = ref(null)

        const emitRefresh = () => {
            emit('refresh')
        }

        const emitLoadMore = () => {
            emit('loadmore')
        }

        const emitSelect = (member: SenderT) => {
            if (!member.full_address || !member.latitude || !member.longitude) {
                startEditSender(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите адрес',
                })
                return
            }

            if (!member.phone) {
                startEditSender(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите номер телефона',
                })
                return
            }

            emit('select', member)
        }

        const emitSearch = useDebounce(() => {
            emit('search', unref(search_query))
        }, 1000)

        const {
            closeSenderActions,
            startCreateSender,
            startEditSender,
            createSender,
            updateSender,
        } = useMembersActions()

        const createSenderHandler = async (sender_payload: SenderCreatePayload) => {
            try {
                await createSender(sender_payload)
                closeSenderActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка создания отправителя',
                    },
                    error
                )
            }
        }

        const updateSenderHandler = async (sender_payload: SenderCreatePayload) => {
            if (!sender_payload.id) return

            try {
                await updateSender(sender_payload.id, sender_payload)
                closeSenderActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка редактирования отправителя',
                    },
                    error
                )
            }
        }

        const emitDelete = (id: number) => {
            emit('delete', id)
        }

        return {
            emitRefresh,
            emitLoadMore,
            emitSelect,
            isSenderActionsOpened,
            closeSenderActions,
            startCreateSender,
            startEditSender,
            createSenderHandler,
            updateSenderHandler,
            search_query,
            emitSearch,
            emitDelete,
        }
    },
})

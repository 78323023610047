
import { defineComponent, onBeforeMount, PropType, reactive, watch } from 'vue'
import { useDebounce } from 'vue-composable'

// Components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'

// Module components
import PointInPanel from '@/modules/CreateOrder/components/PointInPanel/index.vue'
import PanelsLoader from '@/modules/CreateOrder/components/PanelsLoader/index.vue'
// import PanelsFailed from '@/modules/CreateOrder/components/PanelsFailed/index.vue'
import ReceiverCreate from '@/modules/CreateOrder/components/NewReceiverCreate/index.vue'

// Icons
import iSearch from '@/assets/icons/Search.svg'
import iBigPlus from '@/assets/icons/BigPlus.svg'

// Types
import { ReceiverT } from '@/core/types/common.types'
import { MappedCityT } from '@/core/types/Delivery.types'

// Realisations
import { ReceiversRealisation } from '@/core/realisations/Receivers.realisation'
import { isReceiverActionsOpened, useMembersActions } from '../../composable/useMembersActions'
import { ReceiverCreatePayload } from '@/core/types/Order.types'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        's-modal': SModal,
        //
        'point-in-panel': PointInPanel,
        'panels-loader': PanelsLoader,
        // 'panels-failed': PanelsFailed,
        'receiver-create': ReceiverCreate,
        //
        'icon-search': iSearch,
        'icon-big-plus': iBigPlus,
    },
    props: {
        city: {
            type: Object as PropType<MappedCityT>,
            default: null,
        },
        selectedReceiversIds: {
            type: Array as PropType<number[]>,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['select'],
    setup(props, { emit }) {
        const receivers = new ReceiversRealisation()
        const notifications = useNotifications()

        const state = reactive({
            is_loading: false,
            is_failed: false,
            is_search: false,
            search_query: null as string | null,
            list: [] as ReceiverT[],
            pagination: {
                current_page: 1,
                total_pages: 1,
            },
        })

        const loadReceivers = async (page: number) => {
            state.is_search = false
            state.pagination.current_page = page

            try {
                state.is_loading = true

                const response = await receivers.getAllFTL(1, props.city.id)
                state.list = response.data.receivers
                state.pagination.total_pages = response.data.total_pages

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const loadMore = async () => {
            state.is_search = false
            state.pagination.current_page++

            try {
                state.is_loading = true

                const response = await receivers.getAllFTL(
                    state.pagination.current_page,
                    props.city.id
                )
                state.list = state.list.concat(response.data.receivers)
                state.pagination.total_pages = response.data.total_pages

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const searchReceiver = async () => {
            if (!state.search_query) {
                loadReceivers(1)
                return
            }

            try {
                state.is_search = true
                state.is_loading = true

                const response = await receivers.search(state.search_query, props.city.id)
                state.list = response.data.receivers

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const searchReceiverHandler = useDebounce(searchReceiver, 1000)

        const {
            closeReceiverActions,
            startCreateReceiver,
            startEditReceiver,
            createReceiver,
            updateReceiver,
        } = useMembersActions()

        watch(
            () => props.city,
            () => {
                state.list = []
                state.search_query = null
                state.pagination.current_page = 1
                state.pagination.total_pages = 1

                loadReceivers(1)
            }
        )

        onBeforeMount(() => {
            if (props.city) {
                loadReceivers(1)
            }
        })

        const createReceiverHandler = async (receiver_payload: ReceiverCreatePayload) => {
            try {
                await createReceiver(receiver_payload)
                loadReceivers(state.pagination.current_page)
                closeReceiverActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка создания получателя',
                    },
                    error
                )
            }
        }

        const updateReceiverHandler = async (receiver_payload: ReceiverCreatePayload) => {
            if (!receiver_payload.id) return

            try {
                await updateReceiver(receiver_payload.id, receiver_payload)
                loadReceivers(state.pagination.current_page)
                closeReceiverActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка редактирования получателя',
                    },
                    error
                )
            }
        }

        const deleteReceiverHandler = async (id: number) => {
            try {
                await receivers.delete(id)

                const index = state.list.findIndex((receiver) => receiver.id === id)
                if (index >= 0) {
                    state.list.splice(index, 1)
                }
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка удаления отправителя',
                    },
                    error
                )
            }
        }

        const emitSelect = (member: ReceiverT) => {
            if (
                !member.full_address || !member.latitude || !member.longitude
            ) {
                startEditReceiver(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите адрес',
                })
                return
            }

            if (!member.phone) {
                startEditReceiver(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите номер телефона',
                })
                return
            }

            emit('select', member)
        }

        return {
            state,
            searchReceiverHandler,
            loadMore,
            loadReceivers,
            startCreateReceiver,
            startEditReceiver,
            closeReceiverActions,
            createReceiver,
            updateReceiver,
            isReceiverActionsOpened,
            createReceiverHandler,
            updateReceiverHandler,
            deleteReceiverHandler,
            emitSelect,
        }
    },
})

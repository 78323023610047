
import { defineComponent, reactive } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import useVuelidate from '@vuelidate/core'
import { required, requiredIf } from '@vuelidate/validators'
import { useMaska } from '@/common/composable/useMaska'

export default defineComponent({
    components: {
        's-input': SInput,
        's-text-area': STextArea,
        's-button': SButton,
        's-checkbox': SCheckbox,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        receiverIdx: {
            type: Number,
            default: null,
        },
    },
    emits: ['submit'],
    setup(props, { emit }) {
        const state = reactive({
            amount_to_accept_payment: null as string | null,
            extra_info: null as string | null,
            is_spark_verification: false,
            is_kaspi_verification: false,
            verification_invoice_number: null as string | null,
        })

        const v$ = useVuelidate(
            {
                // extra_info: { required },
                verification_invoice_number: {
                    required: requiredIf(() => state.is_kaspi_verification),
                },
            },
            state
        )

        const { unmaskNumbers } = useMaska()

        const emitSubmit = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            let amount_to_accept_payment = 0
            if (state.amount_to_accept_payment) {
                amount_to_accept_payment = Number(unmaskNumbers(state.amount_to_accept_payment))
            }

            emit('submit', {
                amount_to_accept_payment,
                extra_info: state.extra_info,
                is_spark_verification: state.is_spark_verification,
                is_kaspi_verification: state.is_kaspi_verification,
                verification_invoice_number: state.verification_invoice_number,
                receiver_idx: props.receiverIdx,
            })
        }

        const onCheckboxChange = (type: string) => {
            if (type === 'spark') state.is_kaspi_verification = false
            else state.is_spark_verification = false
        }

        return { state, v$, emitSubmit, onCheckboxChange }
    },
})


import { defineComponent, reactive } from 'vue'

// components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

// validation
import useVuelidate from '@vuelidate/core'
import { required, numeric, integer, between } from '@vuelidate/validators'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['add-item'],
    setup(props, { emit }) {
         const state = reactive({
            title: null as string | null,
            price: null as string | null,
            count: null as string | null,
        })

        const rules = {
            title: { required },
            price: { numeric },
            count: { required, integer, between: between(1, 9999) },
        }

        const v$ = useVuelidate(rules, state)

        const emitAddItem = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            emit(
                'add-item',
                {
                    title: state.title,
                    price: Number(state.price),
                    count: Number(state.count),
                }
            )

            state.title = null
            state.price = null
            state.count = null

            v$.value.$reset()
        }

        return { state, v$, emitAddItem }
    }
})


import { computed, defineComponent } from 'vue'

import iClock from '@/assets/icons/Clock.svg'

import { maska } from 'maska'

export default defineComponent({
    directives: {
        maska,
    },
    components: {
        'icon-clock': iClock,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isInvalid: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const timeTokens = {
            h: { pattern: /[0-2]/ },
            H: { pattern: /[0-9]/ },
            '*': { pattern: /[0-3]/ },
            m: { pattern: /[0-5]/ },
            M: { pattern: /[0-9]/ },
        }

        const timeMaskFull = 'hH:mM'
        const timeMaskShort = 'h*:mM'

        const timeMask = computed(() => {
            if (!props.value) return timeMaskFull
            if (props.value[0] === '2') return timeMaskShort
            return timeMaskFull
        })

        const emitInput = (event: Event) => {
            const target = event.target as HTMLInputElement
            emit('update:value', target.value)
        }

        const emitChange = (event: Event) => {
            const target = event.target as HTMLInputElement
            emit('change', target.value)
        }

        return { timeTokens, timeMask, emitInput, emitChange }
    },
})

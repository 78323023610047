import { OptionT } from '@/core/types/common.types'

export const FTL_TOKEN = 'FTL'
export const FTL_ORDER_TYPE = { id: 1, name: 'FTL', value: FTL_TOKEN }

export const LIFTING_CAPACITY_OPTIONS: OptionT<number>[] = [
    {
        id: 1,
        name: '1.5 тонны',
        value: 1.5,
    },
    {
        id: 2,
        name: '3 тонны',
        value: 3,
    },
    {
        id: 3,
        name: '5 тонн',
        value: 5,
    },
    {
        id: 4,
        name: '7 тонн',
        value: 7,
    },
    {
        id: 5,
        name: '10 тонн',
        value: 10,
    },
    {
        id: 6,
        name: '20 тонн',
        value: 20,
    },
]

export const LOADING_TYPE_OPTIONS: OptionT<string>[] = [
    {
        id: 1,
        name: 'Боковая',
        value: 'sideway',
    },
    {
        id: 2,
        name: 'Верхняя',
        value: 'top',
    },
    {
        id: 3,
        name: 'Задняя',
        value: 'backward',
    },
]

export const FTL_STATUS_SEARCHING_TOKEN = 'searching'
export const FTL_STATUS_WAIT_CONFIRM_TOKEN = 'wait_confirm'
export const FTL_STATUS_ACCEPTED_TOKEN = 'accepted'
export const FTL_STATUS_CANCELED_TOKEN = 'canceled'

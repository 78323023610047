
import { defineComponent, reactive } from 'vue'

import OrderTypes from '@/modules/CreateOrder/components/OrderTypes/index.vue'
import SSwitch from '@/common/components/SSwitch/index.vue'
import SModal from '@/common/components/SModal/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'

// import iPerson from '@/assets/icons/Person.svg'
// import iPhone from '@/assets/icons/Phone.svg'
import iClose from '@/assets/icons/Close.svg'

import FTLBidTemplate from '@/modules/CreateOrder/components/FTLBidTemplate/index.vue'
import LTLTemplate from '@/modules/CreateOrder/components/LTLTemplate/index.vue'
import ExpressTemplate from '@/modules/CreateOrder/components/ExpressMultiTemplate/index.vue'

// import SystemNotification from '@/common/components/SystemNotification/index.vue'

import {
    isFTL,
    isLTL,
    isEXPRESS,
    CurrentOrderType,
    useCreateOrder,
} from '@/modules/CreateOrder/composable/useCreateOrder'
import { ORDER_TYPE_OPTIONS } from '@/core/constants/Order.constants'

import { useTitle } from 'vue-composable'
import { useMaska } from '@/common/composable/useMaska'
import useNotifications from '@/common/composable/useNotifications'

import { UsersRealisation } from '@/core/realisations/Users.realisation'

const SOON_TEXT = 'Станет доступно в январе 2023'

const ORDER_TYPES = [
    {
        id: 1,
        type: 'LTL',
        title: 'Доставка посылок и грузов',
        hint: null,
        titleMaxWidth: 108,
        disabled: false,
        img: 'ltl-1.png',
        img_width: '239px',
        img_height: '170px',
        img_bottom: '40px',
        img_right: '0',
        img_left: null,
        img_top: null,
    },
    {
        id: 2,
        type: 'LTL',
        title: 'Экспресс авиа доставка',
        hint: null,
        titleMaxWidth: 168,
        disabled: false,
        img: 'ltl-2.png',
        img_width: '100%',
        img_height: '206px',
        img_bottom: '30px',
        img_right: '0',
        img_left: null,
        img_top: null,
    },
    {
        id: 3,
        type: 'Express',
        title: 'Доставка за 3 часа',
        hint: null,
        titleMaxWidth: 108,
        disabled: false,
        img: 'express-1.png',
        img_width: '198px',
        img_height: '209px',
        img_bottom: '30px',
        img_right: '0',
        img_left: null,
        img_top: null,
    },
    {
        id: 4,
        type: 'FTL',
        title: 'Перевозка отдельным автомобилем',
        hint: null,
        titleMaxWidth: 151,
        disabled: false,
        img: 'ftl-1.png',
        img_width: '180px',
        img_height: '193px',
        img_bottom: '30px',
        img_right: '30px',
        img_left: null,
        img_top: null,
    },
    {
        id: 5,
        type: 'LTL',
        title: 'Доставка внутри города',
        hint: null,
        titleMaxWidth: 224,
        disabled: false,
        img: 'ltl-3.png',
        img_width: '240px',
        img_height: '165px',
        img_bottom: '50px',
        img_right: '5px',
        img_left: null,
        img_top: null,
    },
    {
        id: 6,
        type: 'Purple',
        title: 'Ответственное хранение в Алматы',
        hint: null,
        titleMaxWidth: 172,
        disabled: false,
        img: 'purple-1.png',
        img_width: '236px',
        img_height: '145px',
        img_bottom: '50px',
        img_right: '20px',
        img_left: null,
        img_top: null,
    },
    {
        id: 7,
        type: 'Purple',
        title: 'Фулфиллмент для продавцов маркетплейсов',
        hint: null,
        titleMaxWidth: 176,
        disabled: false,
        img: 'purple-2.png',
        img_width: '250px',
        img_height: '114px',
        img_bottom: '70px',
        img_right: '0',
        img_left: null,
        img_top: null,
    },
]

const ORDER_TYPES_SOON = [
    {
        id: 1001,
        type: 'FTL',
        title: 'Пункты выдачи заказов РК',
        hint: SOON_TEXT,
        titleMaxWidth: 173,
        disabled: true,
        img: 'pvz.png',
        img_width: '230px',
        img_height: '159px',
        img_bottom: '50px',
        img_right: '0',
        img_left: null,
        img_top: null,
    },
    {
        id: 1002,
        type: 'Green',
        title: 'Доставка в другие страны',
        hint: SOON_TEXT,
        titleMaxWidth: 188,
        disabled: true,
        img: 'green-1.png',
        img_width: '100%',
        img_height: '224px',
        img_bottom: '30px',
        img_right: '0',
        img_left: null,
        img_top: null,
    },
    {
        id: 1003,
        type: 'OZON',
        title: 'Доставка до WB и OZON',
        hint: SOON_TEXT,
        titleMaxWidth: 160,
        disabled: true,
    },
]

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default defineComponent({
    components: {
        'order-types': OrderTypes,
        's-switch': SSwitch,
        's-modal': SModal,
        's-input': SInput,
        's-button': SButton,
        'icon-close': iClose,
        'ftl-bid-template': FTLBidTemplate,
        'ltl-template': LTLTemplate,
        'express-template': ExpressTemplate,
        // 'system-notification': SystemNotification,
    },
    setup() {
        const users = new UsersRealisation()
        useTitle('Создание нового заказа')

        const { setOrderType } = useCreateOrder()
        const notifications = useNotifications()

        const form_state = reactive({
            is_opened: false,
            is_loading: false,
            name: null as string | null,
            phone_number: null as string | null,
        })

        const form_state$ = useVuelidate(
            {
                name: { required },
                phone_number: { required, minLength: minLength(18), maxLength: maxLength(18) },
            },
            form_state
        )

        const onOpenForm = () => {
            form_state.is_opened = true
        }

        const closeForm = () => {
            form_state.is_opened = false
            form_state.name = null
            form_state.phone_number = null
            form_state$.value.$reset()
        }

        const submitForm = async () => {
            form_state$.value.$touch()
            if (form_state$.value.$invalid) return

            try {
                form_state.is_loading = true

                const { unmaskNumbers } = useMaska()
                const phoneNumber = '+' + unmaskNumbers(form_state.phone_number as string)

                const payload = {
                    name: form_state.name,
                    phoneNumber,
                }

                await users.createProposal(payload)

                notifications.success({
                    title: 'Заявка отправлена',
                    text: 'Ваша заявка будет рассмотрена в ближайшее время',
                    group: 'create-module',
                    type: 'success',
                })
                closeForm()
            } catch (error) {
                console.error(error)
                notifications.failure(
                    {
                        title: 'При отправке заявки произошла ошибка',
                        text: 'Попробуйте ещё раз',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                form_state.is_loading = false
            }
        }

        return {
            isFTL,
            isLTL,
            isEXPRESS,
            ORDER_TYPE_OPTIONS,
            ORDER_TYPES,
            ORDER_TYPES_SOON,
            CurrentOrderType,
            setOrderType,
            onOpenForm,
            closeForm,
            form_state,
            form_state$,
            submitForm,
        }
    },
})


import { computed, defineComponent, onMounted, reactive, ref } from 'vue'

import OrderProgress from '@/modules/CreateOrder/components/OrderProgress/index.vue'
// import AdditionalServices from '@/modules/CreateOrder/components/AdditionalServices/index.vue'
import NewLtlAdditionalServices from '@/modules/CreateOrder/components/NewLtlAdditionalServices/index.vue'
import SendersPanel from '@/modules/CreateOrder/components/SendersPanel/index.vue'
import SendDateTimePeriod from '@/modules/CreateOrder/components/SendDateTimePeriod/index.vue'
import LTLReceiversPanel from '@/modules/CreateOrder/components/LTLReceiversPanel/index.vue'
import LTLReceiver from '@/modules/CreateOrder/components/LTLReceiver/index.vue'
import LTLCargoPayment from '@/modules/CreateOrder/components/LTLCargoPayment/index.vue'
import LTLInvoices from '@/modules/CreateOrder/components/LTLInvoices/index.vue'

import SModal from '@/common/components/SModal/index.vue'
import SuccessOrder from '@/modules/CreateOrder/components/SuccessOrder/index.vue'

import {
    isFTL,
    isLTL,
    LTLOrderState,
    useCreateOrder,
} from '@/modules/CreateOrder/composable/useCreateOrder'

import useNotifications from '@/common/composable/useNotifications'
import { SenderT } from '@/core/types/common.types'
import { AdditionalServiceBilling } from '@/new-services/types/billing'
const NOTIFICATIONS_GROUP = 'create-module'

// @new-services
import billingAPI from '@/new-services/billing.service'

export default defineComponent({
    components: {
        'order-progress': OrderProgress,
        // 'additional-services': AdditionalServices,
        'new-ltl-additional-services': NewLtlAdditionalServices,
        'senders-panel': SendersPanel,
        'send-date-time-period': SendDateTimePeriod,
        'ltl-receivers-panel': LTLReceiversPanel,
        'ltl-receiver': LTLReceiver,
        'ltl-cargo-payment': LTLCargoPayment,
        'ltl-invoices': LTLInvoices,
        's-modal': SModal,
        'success-order': SuccessOrder,
    },
    setup() {
        const state = reactive({
            company_name: null as string | null,
            company_id: null as number | null,
            is_billing_loading: false,
            additional_services: [] as AdditionalServiceBilling[],
        })

        const notifications = useNotifications()

        const {
            searchSenders,
            searchReceivers,
            loadSenders,
            loadMoreSenders,
            selectSender,
            resetSender,
            setDatePayload,
            resetDatePayload,
            loadReceivers,
            loadMoreReceivers,
            selectReceiver,
            resetLTLReceiver,
            addLTLInvoice,
            removeLTLInvoice,
            setAdditionalServicesForLTLInvoice,
            resetAdditionalServicesForLTLInvoice,
            startSelectLTLReceiver,
            startAddLTLInvoice,
            create,
            reset,
            setAdditionalServices,
            closeAdditionalServices,
            openAdditionalServices,
            resetAdditionalServices,
            createByExcel,
            removeSender,
            removeReceiver,
            startUpdateLTLInvoice,
            stopUpdateLTLInvoice,
            updateLTLInvoice,
        } = useCreateOrder()

        const loadSendersHandler = async () => {
            try {
                await loadSenders()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения отправителей',
                    },
                    error
                )
            }
        }

        const loadMoreSendersHandler = async () => {
            try {
                await loadMoreSenders()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения отправителей',
                    },
                    error
                )
            }
        }

        const searchSendersHandler = async (search_query: string) => {
            try {
                await searchSenders(search_query)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка поиска отправителей',
                    },
                    error
                )
            }
        }

        const loadReceiversHandler = async () => {
            try {
                await loadReceivers()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения получателей',
                    },
                    error
                )
            }
        }

        const loadMoreReceiversHandler = async () => {
            try {
                await loadMoreReceivers()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения получателей',
                    },
                    error
                )
            }
        }

        const searchReceiversHandler = async (search_query: string) => {
            try {
                await searchReceivers(search_query)
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка поиска получателей',
                    },
                    error
                )
            }
        }

        const isSuccessModalOpened = ref(false)
        const closeSuccessModal = () => {
            isSuccessModalOpened.value = false
        }

        const resetHandler = () => {
            reset()
            closeSuccessModal()
        }

        const createHandler = async () => {
            try {
                await create()
                isSuccessModalOpened.value = true
                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'ltl',
                    title: 'Заказ создан',
                })
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка создания заказа',
                    },
                    error
                )
            }
        }

        const createByExcelHandler = async (file: File | undefined) => {
            if (!file) return

            try {
                await createByExcel(file)
                isSuccessModalOpened.value = true

                notifications.success({
                    group: NOTIFICATIONS_GROUP,
                    type: 'ltl',
                    title: 'Заказ создан',
                })
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка создания заказа через Excel',
                    },
                    error
                )
            }
        }

        const senderCityId = computed(() => {
            if (LTLOrderState.sender) return LTLOrderState.sender.city_id
            return null
        })

        const loadBilling = async () => {
            if (!state.company_id || !isLTL) return

            try {
                state.is_billing_loading = true

                const response = await billingAPI.getAdditionalServicesBilling(state.company_id)

                state.additional_services = response.data.data
            } catch (error) {
                console.error(error)
            } finally {
                state.is_billing_loading = false
            }
        }

        const onSelectSender = (sender: SenderT) => {
            state.company_name = sender.company_name
            state.company_id = sender.company_id

            selectSender(sender)
            loadBilling()
        }

        const isBosch = computed(() => state.company_id === 914)

        //

        onMounted(loadSendersHandler)

        //

        return {
            state,
            searchSendersHandler,
            searchReceiversHandler,
            isFTL,
            isLTL,
            LTLOrderState,
            loadMoreSendersHandler,
            selectSender,
            resetSender,
            setDatePayload,
            resetDatePayload,
            loadReceiversHandler,
            loadMoreReceiversHandler,
            selectReceiver,
            resetLTLReceiver,
            addLTLInvoice,
            removeLTLInvoice,
            setAdditionalServicesForLTLInvoice,
            resetAdditionalServicesForLTLInvoice,
            startSelectLTLReceiver,
            startAddLTLInvoice,
            createHandler,
            isSuccessModalOpened,
            closeSuccessModal,
            resetHandler,
            setAdditionalServices,
            closeAdditionalServices,
            openAdditionalServices,
            resetAdditionalServices,
            createByExcelHandler,
            removeSender,
            removeReceiver,
            senderCityId,
            onSelectSender,
            startUpdateLTLInvoice,
            stopUpdateLTLInvoice,
            updateLTLInvoice,
            isBosch,
        }
    },
})


import {
    computed,
    // defineAsyncComponent,
    defineComponent,
    PropType,
    reactive,
    ref,
    toRaw,
} from 'vue'

// import AdditionalService from '@/modules/CreateOrder/components/AdditionalService/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

// import SAsyncModalLoader from '@/common/components/SAsyncModalLoader/index.vue'

// import SModal from '@/common/components/SModal/index.vue'

// const TariffsAndCost = defineAsyncComponent({
//     loader: () => import('@/modules/CreateOrder/components/TariffsAndCost/index.vue'),
//     loadingComponent: SAsyncModalLoader,
// })

import iClose from '@/assets/icons/Close.svg'

// import { useModal } from '@/common/composable/useModal'

import { isFTL, isLTL } from '@/modules/CreateOrder/composable/useCreateOrder'
import { EMPTY_ADDITIONAL_SERVICES } from '@/core/constants/common.constants'
import { AdditionalServicesT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        // 'additional-service': AdditionalService,
        's-button': SButton,
        's-checkbox': SCheckbox,
        // 's-modal': SModal,
        // 'tariffs-and-cost': TariffsAndCost,
        'icon-close': iClose,
    },
    emits: ['continue', 'close'],
    props: {
        initialValues: {
            type: Object as PropType<AdditionalServicesT>,
            default: null,
        },
        setted: {
            type: Boolean,
            default: false,
        },
        closable: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const themeClass = computed(() => {
            if (isFTL.value) return 'red'
            if (isLTL.value) return 'primary'
            return null
        })

        let initialServices = { ...EMPTY_ADDITIONAL_SERVICES }

        if (props.initialValues) {
            initialServices = { ...props.initialValues }
        }

        const state = reactive(initialServices)

        const emitContinue = () => {
            emit('continue', toRaw(state))
        }

        const emitClose = () => {
            emit('close')

            state.hasCar = false
            state.hasSoftPackage = false
            state.hasManipulator = false
            state.hasCrane = false
            state.hasHydraulicTrolley = false
            state.hasGrid = false
            state.hasLoader = false
            state.hasPallet = false
        }

        // const isTariffsAndCostModalOpened = ref(false)
        // const { openModal, closeModal } = useModal(isTariffsAndCostModalOpened)

        return {
            isFTL,
            isLTL,
            emitClose,
            emitContinue,
            themeClass,
            // isTariffsAndCostModalOpened,
            // openModal,
            // closeModal,
            state,
        }
    },
})


import { defineComponent, ref } from 'vue'

export default defineComponent({
    setup() {
        const is_image_showed = ref(false)

        const trigger = () => {
            is_image_showed.value = !is_image_showed.value
        }

        return { is_image_showed, trigger }
    },
})


import { defineComponent, PropType } from 'vue'

import SButton from '@/common/components/SButton/index.vue'

export default defineComponent({
    components: {
        's-button': SButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false  
        },
        items: {
            type: Array as PropType<any[]>,
            default: () => []
        }
    },
    emits: ['remove-item'],
    setup(props, { emit }) {
        const emitRemoveItem = (item_index: number) => {
            if (props.disabled) return
            emit('remove-item', item_index)
        }

        return { emitRemoveItem }
    }
})


import { defineComponent, PropType } from 'vue'

// Icons
import iEdit from '@/assets/icons/Edit.svg'

const SPARK_VERIFICATION_ID = 1
const KASPI_VERIFICATION_ID = 2

export default defineComponent({
    components: {
        'icon-edit': iEdit,
    },
    props: {
        amountToAcceptPayment: {
            type: [Number, null] as PropType<number | null>,
            default: 0,
        },
        extraInfo: {
            type: [String, null] as PropType<string | null>,
            default: null,
        },
        verificationType: {
            type: [Number, null] as PropType<number | null>,
            default: null,
        },
        verificationInvoiceNumber: {
            type: [String, null] as PropType<string | null>,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        receiverIdx: {
            type: Number,
            default: null,
        },
    },
    emits: ['reset'],
    setup(props, { emit }) {
        const emitReset = () => {
            emit('reset', props.receiverIdx)
        }

        return { emitReset, SPARK_VERIFICATION_ID, KASPI_VERIFICATION_ID }
    },
})

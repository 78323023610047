
import { defineComponent, reactive, onBeforeMount } from 'vue'

// components
import SButton from '@/common/components/SButton/index.vue'

// module components
import ExpressCity from '@/modules/CreateOrder/components/ExpressCity/index.vue'
import ExpressSenderPanel from '@/modules/CreateOrder/components/ExpressSenderPanel/index.vue'
import ExpressSender from '@/modules/CreateOrder/components/ExpressSender/index.vue'
import ExpressReceiverPanel from '@/modules/CreateOrder/components/ExpressReceiverPanel/index.vue'
import ExpressInvoiceForm from '@/modules/CreateOrder/components/ExpressInvoiceForm/index.vue'
import ExpressAreaLimit from '@/modules/CreateOrder/components/ExpressAreaLimit/index.vue'
import ExpressMultiTariffs from '@/modules/CreateOrder/components/ExpressMultiTariffs/index.vue'

// realisations
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'

// types
import { DeliveryTaskT } from '@/core/types/Delivery.types'

// composable
import useNotifications from '@/common/composable/useNotifications'
import useExpressMultiOrder from '@/modules/CreateOrder/composable/useExpressMultiOrder'

export default defineComponent({
    components: {
        's-button': SButton,
        'express-city': ExpressCity,
        'express-sender-panel': ExpressSenderPanel,
        'express-sender': ExpressSender,
        'express-receiver-panel': ExpressReceiverPanel,
        'express-invoice-form': ExpressInvoiceForm,
        'express-area-limit': ExpressAreaLimit,
        'express-multi-tariffs': ExpressMultiTariffs,
    },
    setup() {
        const {
            state,
            resetState,
            tariffs_state,
            loadTariffs,
            onCitySelect,
            onSenderSelect,
            onSenderReset,
            onReceiverSelect,
            onInvoiceRemove,
            onSelectTask,
            onUnselectTask,
            onAddItem,
            onRemoveItem,
            onSubmitAdditionalData,
            onResetAdditionalData,
            onSelectTariff,
            createOrder,
            isCitySelected,
            isSenderSelected,
            coordinates,
            selectedTariffId,
            selectedTariffPrice,
            isActionsDisabled,
        } = useExpressMultiOrder()

        const delivery = new DeliveryRealisation()
        const notifications = useNotifications()

        const tasks_state = reactive({
            is_tasks_loading: false,
            is_tasks_failed: false,
            tasks: [] as DeliveryTaskT[],
        })

        const loadTasks = async () => {
            try {
                tasks_state.is_tasks_loading = true

                const response = await delivery.getTasks(1)
                tasks_state.tasks = response.data

                tasks_state.is_tasks_failed = false
            } catch (error) {
                console.error(error)
                tasks_state.is_tasks_failed = true

                notifications.failure(
                    {
                        title: 'При загрузке списка задач произошла ошибка',
                        type: 'error',
                        group: 'create-module',
                    },
                    error
                )
            } finally {
                tasks_state.is_tasks_loading = false
            }
        }

        onBeforeMount(loadTasks)

        return {
            state,
            resetState,
            tasks_state,
            tariffs_state,
            loadTariffs,
            onCitySelect,
            onSenderSelect,
            onSenderReset,
            onReceiverSelect,
            onInvoiceRemove,
            onSelectTask,
            onUnselectTask,
            onAddItem,
            onRemoveItem,
            onSubmitAdditionalData,
            onResetAdditionalData,
            loadTasks,
            onSelectTariff,
            createOrder,
            isCitySelected,
            isSenderSelected,
            coordinates,
            selectedTariffId,
            selectedTariffPrice,
            isActionsDisabled,
        }
    },
})


import { defineComponent, PropType } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

import { DeliveryTariffT } from '@/core/types/Delivery.types'

export default defineComponent({
    components: {
        's-button': SButton,
        's-loader': SLoader,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
            default: null,
        },
        selectedTariffId: {
            type: Number,
            default: null,
        },
        distance: {
            type: Number,
            default: 0,
        },
        tariffs: {
            type: Array as PropType<DeliveryTariffT[]>,
            default: () => [],
        },
    },
    emits: ['reload', 'select'],
    setup(props, { emit }) {
        const emitReload = () => {
            if (props.disabled) return
            emit('reload')
        }

        const emitSelect = (tariff: DeliveryTariffT) => {
            if (props.disabled) return
            emit('select', { ...tariff })
        }

        return { emitReload, emitSelect }
    },
})

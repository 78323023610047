
import { defineComponent, onBeforeMount, PropType, reactive, watch } from 'vue'
import { useDebounce } from 'vue-composable'

// Components
import SInput from '@/common/components/SInput/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SModal from '@/common/components/SModal/index.vue'

// Module components
import PointInPanel from '@/modules/CreateOrder/components/PointInPanel/index.vue'
import PanelsLoader from '@/modules/CreateOrder/components/PanelsLoader/index.vue'
// import PanelsFailed from '@/modules/CreateOrder/components/PanelsFailed/index.vue'
import SenderCreate from '@/modules/CreateOrder/components/SenderCreate/index.vue'

// Icons
import iSearch from '@/assets/icons/Search.svg'
import iBigPlus from '@/assets/icons/BigPlus.svg'

// Types
import { SenderT } from '@/core/types/common.types'
import { MappedCityT } from '@/core/types/Delivery.types'

// Realisations
import { SendersRealisation } from '@/core/realisations/Senders.realisation'
import { isSenderActionsOpened, useMembersActions } from '../../composable/useMembersActions'
import { SenderCreatePayload } from '@/core/types/Order.types'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-input': SInput,
        's-button': SButton,
        's-modal': SModal,
        //
        'point-in-panel': PointInPanel,
        'panels-loader': PanelsLoader,
        // 'panels-failed': PanelsFailed,
        'sender-create': SenderCreate,
        //
        'icon-search': iSearch,
        'icon-big-plus': iBigPlus,
    },
    props: {
        city: {
            type: Object as PropType<MappedCityT>,
            default: null,
        },
    },
    emits: ['select'],
    setup(props, { emit }) {
        const senders = new SendersRealisation()
        const notifications = useNotifications()

        const state = reactive({
            is_loading: false,
            is_failed: false,
            is_search: false,
            search_query: null as string | null,
            list: [] as SenderT[],
            pagination: {
                current_page: 1,
                total_pages: 1,
            },
        })

        const loadSenders = async (page: number) => {
            state.is_search = false
            state.pagination.current_page = page

            try {
                state.is_loading = true

                const response = await senders.getAll(1, props.city.id)
                state.list = response.data.senders
                state.pagination.total_pages = response.data.total_pages

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const loadMore = async () => {
            state.is_search = false
            state.pagination.current_page++

            try {
                state.is_loading = true

                const response = await senders.getAll(state.pagination.current_page, props.city.id)
                state.list = state.list.concat(response.data.senders)
                state.pagination.total_pages = response.data.total_pages

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        const searchSender = async () => {
            if (!state.search_query) {
                loadSenders(1)
                return
            }

            try {
                state.is_search = true
                state.is_loading = true

                const response = await senders.search(state.search_query, props.city.id)
                state.list = response.data

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }
        const searchSenderHandler = useDebounce(searchSender, 1000)

        const {
            closeSenderActions,
            startCreateSender,
            startEditSender,
            createSender,
            updateSender,
        } = useMembersActions()

        watch(
            () => props.city,
            () => {
                state.list = []
                state.search_query = null
                state.pagination.current_page = 1
                state.pagination.total_pages = 1

                loadSenders(1)
            }
        )

        onBeforeMount(() => {
            if (props.city) {
                loadSenders(1)
            }
        })

        const createSenderHandler = async (sender_payload: SenderCreatePayload) => {
            try {
                await createSender(sender_payload, true)
                loadSenders(state.pagination.current_page)
                closeSenderActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка создания отправителя',
                    },
                    error
                )
            }
        }

        const updateSenderHandler = async (sender_payload: SenderCreatePayload) => {
            if (!sender_payload.id) return

            try {
                await updateSender(sender_payload.id, sender_payload, true)
                loadSenders(state.pagination.current_page)
                closeSenderActions()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка редактирования отправителя',
                    },
                    error
                )
            }
        }

        const deleteSenderHandler = async (id: number) => {
            try {
                await senders.delete(id)

                const index = state.list.findIndex((sender) => sender.id === id)
                if (index >= 0) {
                    state.list.splice(index, 1)
                }
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка удаления отправителя',
                    },
                    error
                )
            }
        }

        const emitSelect = (member: SenderT) => {
            if (!member.full_address || !member.latitude || !member.longitude) {
                startEditSender(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите адрес',
                })
                return
            }

            if (!member.phone) {
                startEditSender(member)

                notifications.failure({
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Пожалуйста, укажите номер телефона',
                })
                return
            }

            emit('select', member)
        }

        return {
            state,
            searchSenderHandler,
            loadSenders,
            loadMore,
            startCreateSender,
            startEditSender,
            closeSenderActions,
            createSender,
            updateSender,
            isSenderActionsOpened,
            createSenderHandler,
            updateSenderHandler,
            deleteSenderHandler,
            emitSelect,
        }
    },
})

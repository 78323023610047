
import { defineComponent, PropType } from 'vue'

import OrderPoint from '@/modules/CreateOrder/components/OrderPoint/index.vue'
import { SenderT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        'order-point': OrderPoint,
    },
    props: {
        sender: {
            type: Object as PropType<SenderT>,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['reset'],
    setup(_, { emit }) {
        const emitReset = () => {
            emit('reset')
        }

        return { emitReset }
    },
})

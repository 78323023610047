
import { defineComponent, PropType, reactive, toRaw } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import iClose from '@/assets/icons/Close.svg'

import { NEW_LTL_EMPTY_ADDITIONAL_SERVICES } from '@/core/constants/common.constants'
import { AdditionalServicesT } from '@/core/types/common.types'
import { AdditionalServiceBilling } from '@/new-services/types/billing'

export default defineComponent({
    components: {
        's-button': SButton,
        's-checkbox': SCheckbox,
        'icon-close': iClose,
    },
    emits: ['continue', 'close'],
    props: {
        initialValues: {
            type: Object as PropType<AdditionalServicesT>,
            default: null,
        },
        setted: {
            type: Boolean,
            default: false,
        },
        closable: {
            type: Boolean,
            default: true,
        },
        additionalServices: {
            type: Array as PropType<AdditionalServiceBilling[]>,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        let initialServices = { ...NEW_LTL_EMPTY_ADDITIONAL_SERVICES }

        if (props.initialValues) {
            initialServices = { ...props.initialValues }
        }

        const state = reactive(initialServices)

        const emitContinue = () => {
            emit('continue', toRaw(state))
        }

        const emitClose = () => {
            emit('close')

            state.hasManipulator = false
            state.hasCrane = false
            state.hasHydraulicTrolley = false
            state.hasLoader = false
        }

        const getValue = (service_name: string, key: keyof AdditionalServiceBilling) => {
            const service = props.additionalServices.find((s) => s.name === service_name)
            if (!service) return '-'
            return service[key]
        }

        return {
            emitClose,
            emitContinue,
            getValue,
            state,
        }
    },
})


import { defineComponent, onBeforeMount, PropType, reactive } from 'vue'

// Components
import SButton from '@/common/components/SButton/index.vue'
import SSwitch from '@/common/components/SSwitch/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

// Types
import { DeliveryCityT, MappedCityT } from '@/core/types/Delivery.types'

// Realisation
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'

export default defineComponent({
    components: {
        's-button': SButton,
        's-switch': SSwitch,
        's-loader': SLoader,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        selectedCity: {
            type: Object as PropType<MappedCityT>,
            default: null,
        },
    },
    emits: ['submit', 'reset'],
    setup(props, { emit }) {
        const delivery = new DeliveryRealisation()

        const state = reactive({
            city: null as DeliveryCityT | null,
            list: [] as MappedCityT[],
            is_loading: false,
            is_failed: false,
        })

        const loadCities = async () => {
            try {
                state.is_loading = true

                const response = await delivery.getCities()
                state.list = response.data.map((city) => {
                    return {
                        id: city.id,
                        name: city.name,
                        value: city.id,
                        latitude: city.latitude,
                        longitude: city.longitude,
                        coordinates: city.coordinates
                    }
                })

                setCity(state.list[0])

                state.is_failed = false
            } catch (error) {
                state.is_failed = true
            } finally {
                state.is_loading = false
            }
        }

        onBeforeMount(loadCities)

        const setCity = (city: MappedCityT) => {
            emit('submit', { ...city })
        }

        return { state, loadCities, setCity }
    },
})

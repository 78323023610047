
import { defineComponent, PropType } from 'vue'

import OrderType from '@/modules/CreateOrder/components/OrderType/index.vue'

import { FTL_ORDER_TYPE } from '@/core/constants/FTL.constants'
import { LTL_ORDER_TYPE } from '@/core/constants/LTL.constants'
import { EXPRESS_ORDER_TYPE } from '@/core/constants/Express.constants'

import { useCreateOrder } from '@/modules/CreateOrder/composable/useCreateOrder'

export default defineComponent({
    components: {
        'order-type': OrderType
    },
    props: {
        orderTypes: {
            type: Array as PropType<any[]>,
            default: () => []
        }
    },
    emits: ['open-form'],
    setup(props, { emit }) {
        const { setOrderType } = useCreateOrder()

        const onOpenLtl = () => {
            setOrderType(LTL_ORDER_TYPE)
        }

        const onOpenFtl = () => {
            setOrderType(FTL_ORDER_TYPE)
        }

        const onOpenExpress = () => {
            setOrderType(EXPRESS_ORDER_TYPE)
        }

        const onOpenForm = () => {
            emit('open-form')
        }

        return { onOpenLtl, onOpenFtl, onOpenExpress, onOpenForm }
    }
})

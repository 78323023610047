
import { computed, defineComponent, onBeforeMount, reactive } from 'vue'

// Components
import SButton from '@/common/components/SButton/index.vue'
import SInput from '@/common/components/SInput/index.vue'
import STextArea from '@/common/components/STextArea/index.vue'
import SRadioGroup from '@/common/components/SRadioGroup/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'
import FileLoader from '@/common/components/FileLoader/index.vue'

import iClose from '@/assets/icons/Close.svg'

// Constants
import { PAYMENT_METHOD_OPTIONS, SHIPMENT_TYPE_OPTIONS } from '@/core/constants/common.constants'
import { LOADING_TYPE_OPTIONS } from '@/core/constants/FTL.constants'

// Realisations
import { FTLRealisation } from '@/core/realisations/FTL.realisation'

// Types
import { FTLDangerClass, FTLLoadCapacityT } from '@/core/types/FTL.types'
import { OptionT } from '@/core/types/common.types'

type Capacity = FTLLoadCapacityT & { value: string }
type DangerClass = FTLDangerClass & { value: number }
type TransportType = { id: number; title: string }

// Validations
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, decimal, integer, minLength } from '@vuelidate/validators'

export default defineComponent({
    components: {
        SButton,
        SInput,
        STextArea,
        SRadioGroup,
        SCheckbox,
        iClose,
        FileLoader,
    },
    props: {
        opened: {
            type: Boolean,
            default: false,
        },
        completed: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isNotRequired: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['confirm', 'reset'],
    setup(props, { emit }) {
        const ftlRealisation = new FTLRealisation()

        const state = reactive({
            is_toggle_inputs_clicked: true,
        })

        const form_state = reactive({
            car_info: {
                cubature: null as Capacity | null,
                temperature_mode: null as string | null,
                loading_type: null as { name: string } | null,
                oversized_cargo: false,
                load_capacity: null as Capacity | null,
            },
            description: null as string | null,
            payment_method: PAYMENT_METHOD_OPTIONS[0],
            //
            weight: null as string | null,
            height: null as string | null,
            width: null as string | null,
            depth: null as string | null,
            places: null as string | null,
            //
            is_dangerous: false,
            is_stacked: false,
            is_need_car: false,
            customs_clearance: false,
            //
            danger_class: null as DangerClass | null,
            insurance: null as string | null,
            hs_code: null as string | null,
            packaging: null as string | null,
            special_conditions: null as string | null,
            shipment_type: SHIPMENT_TYPE_OPTIONS[0],
            client_price: '',
            transport_type: null as any,
            dop_order_number: null as string | null,
            invoice_files: [] as File[],
            certificate_of_safety_files: [] as File[],
            //
            isInsecure: false,
            declared_price: '',
            cargo_name: '',
        })

        const isAuto = computed(() => {
            return form_state.shipment_type.value === 1
        })

        const CargoCharacteristicsValidation: any = {
            cargo_name: {
                required: requiredIf(() => form_state.isInsecure),
                minLength: minLength(3),
            },
            declared_price: { required: requiredIf(() => form_state.isInsecure), decimal },
            car_info: {
                load_capacity: {
                    required: requiredIf(
                        () => props.isNotRequired && isAuto.value && !form_state.is_need_car
                    ),
                },
                cubature: {
                    required: requiredIf(
                        () => props.isNotRequired && isAuto.value && !form_state.is_need_car
                    ),
                },
                loading_type: {
                    required: requiredIf(
                        () => props.isNotRequired && isAuto.value && !form_state.is_need_car
                    ),
                },
            },
            description: { required: requiredIf(() => !form_state.is_need_car) },
            weight: {
                required,
                decimal,
            },
            height: {
                required,
                decimal,
            },
            width: {
                required,
                decimal,
            },
            depth: {
                required,
                decimal,
            },
            places: {
                required: requiredIf(() => {
                    return !props.isNotRequired
                }),
                integer,
            },
            client_price: {
                decimal,
            },
            invoice_files: { required: requiredIf(() => form_state.is_dangerous) },
            certificate_of_safety_files: { required: requiredIf(() => form_state.is_dangerous) },
        }

        const v$ = useVuelidate(CargoCharacteristicsValidation, form_state)

        const data_state = reactive({
            capacity: {
                is_loading: false,
                list: [] as Capacity[],
            },
            danger_classes: {
                is_loading: false,
                list: [] as DangerClass[],
            },
            transport_types: {
                is_loading: false,
                list: [] as TransportType[],
            },
        })

        const loadCapacity = async () => {
            try {
                data_state.capacity.is_loading = true
                const response = await ftlRealisation.getLoadCapacity()
                data_state.capacity.list = response.data.map((capacity) => {
                    return {
                        ...capacity,
                        value: capacity.load_capacity,
                    }
                })
            } catch (error) {
                console.error(error)
            } finally {
                data_state.capacity.is_loading = false
            }
        }

        const loadTransportTypes = async () => {
            try {
                data_state.transport_types.is_loading = true
                const response = await ftlRealisation.getTransportTypes()
                data_state.transport_types.list = response.data.map((transport_type) => {
                    return {
                        ...transport_type,
                        value: transport_type.id,
                    }
                })
            } catch (error) {
                console.error(error)
            } finally {
                data_state.transport_types.is_loading = false
            }
        }

        const onCapacityChange = (capacity: Capacity | null) => {
            if (!capacity) {
                form_state.car_info.cubature = null
                return
            }

            form_state.car_info.cubature = capacity
        }

        const onSecureChange = (value: boolean) => {
            if (!value) {
                form_state.cargo_name = ''
                form_state.declared_price = ''
            }
        }

        const loadDangerClasses = async () => {
            try {
                data_state.danger_classes.is_loading = true
                const response = await ftlRealisation.getDangerClasses()
                data_state.danger_classes.list = response.data.map((danger_class) => {
                    return {
                        ...danger_class,
                        value: danger_class.id,
                    }
                })
            } catch (error) {
                console.error(error)
            } finally {
                data_state.danger_classes.is_loading = false
            }
        }

        const prefetchData = () => {
            loadCapacity()
            loadDangerClasses()
            loadTransportTypes()
        }

        onBeforeMount(prefetchData)

        const resetFiles = () => {
            form_state.invoice_files = []
            form_state.certificate_of_safety_files = []
        }

        const emitConfirm = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            const car_info = {
                cubature: form_state.car_info.cubature?.cubature,
                temperature_mode: form_state.car_info.temperature_mode,
                loading_type: form_state.car_info.loading_type?.name,
                oversized_cargo: form_state.car_info.oversized_cargo ? 1 : 0,
                load_capacity: form_state.car_info.load_capacity?.load_capacity,
            }

            const payload = {
                description: form_state.description,
                cargo_name: form_state.cargo_name,
                declared_price: Number(form_state.declared_price),
                client_price: Number(form_state.client_price),
                is_dangerous: form_state.is_dangerous ? 1 : 0,
                is_stacked: form_state.is_stacked ? 1 : 0,
                customs_clearance: form_state.customs_clearance ? 1 : 0,
                weight: Number(form_state.weight),
                height: Number(form_state.height),
                width: Number(form_state.width),
                depth: Number(form_state.depth),
                places: Number(form_state.places),
                danger_class: form_state.danger_class,
                insurance: form_state.insurance,
                hs_code: form_state.hs_code,
                packaging: form_state.packaging,
                special_conditions: form_state.special_conditions,
                payment_method: form_state.payment_method.value,
                shipment_type: form_state.shipment_type.value,
                transport_type: form_state.transport_type ? form_state.transport_type.value : null,
                car_info,
                dop_order_number: form_state.dop_order_number,
                invoice_files: form_state.invoice_files,
                certificate_of_safety_files: form_state.certificate_of_safety_files,
                is_need_car: form_state.is_need_car ? 0 : 1,
            }

            emit('confirm', { ...payload })
        }

        const resetInputs = () => {
            form_state.car_info.load_capacity = null
            form_state.description = null
            form_state.car_info.loading_type = null
            form_state.car_info.cubature = null
            form_state.transport_type = null
            form_state.car_info.temperature_mode = null
            form_state.declared_price = ''
            form_state.cargo_name = ''
            form_state.payment_method = PAYMENT_METHOD_OPTIONS[0]
            form_state.car_info.oversized_cargo = false
            form_state.is_stacked = false
            form_state.customs_clearance = false
        }

        const emitReset = () => {
            form_state.danger_class = null
            form_state.insurance = null
            form_state.hs_code = null
            form_state.packaging = null
            form_state.special_conditions = null
            form_state.shipment_type = SHIPMENT_TYPE_OPTIONS[0]
            form_state.client_price = ''
            form_state.weight = null
            form_state.height = null
            form_state.width = null
            form_state.depth = null
            form_state.places = null
            form_state.dop_order_number = null
            form_state.is_dangerous = false

            resetInputs()
            resetFiles()

            v$.value.$reset()
            emit('reset')
        }

        const toggleInputs = () => {
            state.is_toggle_inputs_clicked = !state.is_toggle_inputs_clicked
        }

        const resetAutoInputs = () => {
            form_state.car_info.cubature = null
            form_state.car_info.load_capacity = null
            form_state.car_info.loading_type = null
            form_state.transport_type = null
        }

        const onShipmentTypeChange = (option: OptionT<number>) => {
            if (option.value === 2) resetAutoInputs()
        }

        const onIsDangerousChanged = (value: boolean) => {
            if (!value) resetFiles()
        }

        const onIsCarChange = () => {
            if (form_state.is_need_car) resetInputs()
        }

        return {
            state,
            form_state,
            data_state,
            //
            v$,
            //
            onCapacityChange,
            onSecureChange,
            onShipmentTypeChange,
            toggleInputs,
            emitConfirm,
            emitReset,
            onIsDangerousChanged,
            onIsCarChange,
            //
            PAYMENT_METHOD_OPTIONS,
            SHIPMENT_TYPE_OPTIONS,
            LOADING_TYPE_OPTIONS,
            //
            isAuto,
        }
    },
})

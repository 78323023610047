
import { computed, defineComponent, onMounted, reactive, ref, toRaw } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import SPhoneNumber from '@/common/components/SPhoneNumber/index.vue'

import STextArea from '@/common/components/STextArea/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SMap from '@/common/components/SMap/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'
import SMapSearch from '@/common/components/SMapSearch/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import iClose from '@/assets/icons/Close.svg'
import iSearch from '@/assets/icons/Search.svg'
import iPerson from '@/assets/icons/Person.svg'
import iLocation from '@/assets/icons/Location.svg'
import iDots from '@/assets/icons/Dots.svg'
import iLattice from '@/assets/icons/Lattice.svg'
import iComment from '@/assets/icons/Comment.svg'

import { isFTL, isLTL } from '@/modules/CreateOrder/composable/useCreateOrder'
import useVuelidate from '@vuelidate/core'

import { CreateSenderValidation } from '@/modules/CreateOrder/validations/CreateSender.validation'

import {
    useMembersActions,
    isCREATE,
    isUPDATE,
    senderCities,
    isSenderActionsLoading,
} from '@/modules/CreateOrder/composable/useMembersActions'

import { CityT } from '@/core/types/common.types'

import MapsRealisation from '@/core/realisations/Maps.realisation'
import { SenderCreateState } from '@/core/types/Order.types'
import { MAP_KEYS } from '@/core/constants/Map.constants'
import { MapSearchResultT } from '@/core/types/Map.types'
import { useMaska } from '@/common/composable/useMaska'
import { WarehouseT } from '@/core/types/Responses/Warehouses.responses'

import { WarehousesRealisation } from '@/core/realisations/Warehouses.realisation'

export default defineComponent({
    components: {
        's-input': SInput,
        's-phone-number': SPhoneNumber,
        //
        's-textarea': STextArea,
        's-button': SButton,
        's-map': SMap,
        's-loader': SLoader,
        's-map-search': SMapSearch,
        's-checkbox': SCheckbox,
        //
        'icon-close': iClose,
        'icon-search': iSearch,
        'icon-person': iPerson,
        // 'icon-city': iCity,
        'icon-location': iLocation,
        'icon-dots': iDots,
        'icon-lattice': iLattice,
        'icon-comment': iComment,
    },
    props: {
        isExpress: {
            type: Boolean,
            default: false,
        },
        exactCityId: {
            type: Number,
            default: null,
        },
    },
    emits: ['create', 'update', 'close'],
    setup(props, { emit }) {
        const { getSenderActionStateRaw, loadSenderCities } = useMembersActions()

        const warehousesAPI = new WarehousesRealisation()

        const warehouses_state = reactive({
            is_opened: false,
            is_loading: false,
            is_failed: false,
            warehouse: null as WarehouseT | null,
        })

        const state: SenderCreateState = reactive({
            title: null,
            full_name: null,
            phone: null,
            additional_phone: null,
            city: null,
            full_address: null,
            latitude: null,
            longitude: null,
            index: null,
            office: null,
            comment: null,
            street: null,
            house: null,
            warehouse_id: null,
        })

        const addressState = reactive({
            is_loading: false,
            is_failed: false,
            is_finded: false,
            address: null as string | null,
            street: null as string | null,
            house: null as string | null,
            longitude: 0,
            latitude: 0,
        })

        const mapState = reactive({
            instance: null,
            is_yandex: true,
            is_google: false,
            is_gis: false,
        })

        const v$ = useVuelidate(CreateSenderValidation, state)

        const findCityById = (city_id: number): CityT | null => {
            if (!city_id) return null
            const findedCity = senderCities.data.find(
                (sender_city: CityT) => sender_city.id === city_id
            )
            if (!findedCity) return null
            return findedCity
        }

        const loadWarehouse = async (city_id: number) => {
            if (!isLTL.value) return

            try {
                warehouses_state.is_loading = true

                const response = await warehousesAPI.getByCityId(city_id)
                warehouses_state.warehouse = response.data

                if (warehouses_state.is_opened) {
                    state.street = warehouses_state.warehouse.street
                    state.house = warehouses_state.warehouse.house
                    state.latitude = Number(warehouses_state.warehouse.latitude)
                    state.longitude = Number(warehouses_state.warehouse.longitude)
                    state.full_address = warehouses_state.warehouse.full_address
                    state.index = warehouses_state.warehouse.index
                    state.office = warehouses_state.warehouse.office
                    state.warehouse_id = warehouses_state.warehouse.id
                }

                warehouses_state.is_failed = false
            } catch (error) {
                console.error(error)
                warehouses_state.is_failed = true

                warehouses_state.warehouse = null
                state.full_address = null
                state.latitude = null
                state.longitude = null
                state.warehouse_id = null
            } finally {
                warehouses_state.is_loading = false
            }
        }

        const onWarehouseToggle = () => {
            if (warehouses_state.is_opened) {
                if (warehouses_state.warehouse) {
                    state.street = warehouses_state.warehouse.street
                    state.house = warehouses_state.warehouse.house
                    state.latitude = Number(warehouses_state.warehouse.latitude)
                    state.longitude = Number(warehouses_state.warehouse.longitude)
                    state.full_address = warehouses_state.warehouse.full_address
                    state.index = warehouses_state.warehouse.index
                    state.office = warehouses_state.warehouse.office
                    state.warehouse_id = warehouses_state.warehouse.id
                }
            } else {
                state.full_address = null
                state.latitude = null
                state.longitude = null
                state.warehouse_id = null
            }
        }

        const onCityChange = (selected_city: CityT | null) => {
            if (!selected_city) {
                warehouses_state.warehouse = null
                warehouses_state.is_opened = false

                state.full_address = null
                state.latitude = null
                state.longitude = null
                state.warehouse_id = null
                return
            }

            loadWarehouse(selected_city.id)
        }

        onMounted(async () => {
            const initialSenderState = getSenderActionStateRaw()

            if (!initialSenderState) {
                await loadSenderCities(props.isExpress, props.exactCityId)
                return
            }

            state.id = initialSenderState.id
            state.title = initialSenderState.title
            state.full_name = initialSenderState.full_name
            state.phone = initialSenderState.phone
            state.additional_phone = initialSenderState.additional_phone
            state.full_address = initialSenderState.full_address
            state.latitude = initialSenderState.latitude
            state.longitude = initialSenderState.longitude
            state.index = initialSenderState.index
            state.office = initialSenderState.office
            state.comment = initialSenderState.comment
            state.street = initialSenderState.street
            state.house = initialSenderState.house
            state.warehouse_id = initialSenderState.warehouse_id

            await loadSenderCities(props.isExpress, props.exactCityId)
            state.city = findCityById(initialSenderState.city_id)
            await loadWarehouse(initialSenderState.city_id)

            if (state.warehouse_id) {
                warehouses_state.is_opened = true
            }

            v$.value.$reset()
        })

        const { unmaskNumbers } = useMaska()

        const emitConfirm = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            const payload: SenderCreateState = toRaw({ ...state })
            if (payload.phone) payload.phone = '+' + unmaskNumbers(payload.phone)
            if (payload.additional_phone)
                payload.additional_phone = '+' + unmaskNumbers(payload.additional_phone)

            if (isCREATE.value) {
                emit('create', payload)
                return
            }

            if (isUPDATE.value) {
                emit('update', payload)
                return
            }
        }

        const emitClose = () => {
            emit('close')
        }

        const isMapOpened = ref(false)

        const openMap = () => {
            v$.value.city.$touch()
            if (v$.value.city.$invalid) return

            isMapOpened.value = true

            if (mapState.instance) {
                getCoordsByAdressYandex()
            }
        }

        const closeMap = () => {
            isMapOpened.value = false
        }

        const getClickedAdressYandex = async (coords: number[]) => {
            try {
                addressState.is_loading = true

                const mapsRealisation = new MapsRealisation()
                const response = await mapsRealisation.getYandexAdressByCoords(coords)

                if (!response) return

                addressState.address = response.full_address
                addressState.street = response.street
                addressState.house = response.house

                addressState.is_failed = false
                addressState.is_finded = true
            } catch (error) {
                console.error(error)
                addressState.is_failed = true
            } finally {
                addressState.is_loading = false
            }
        }

        const getCoordsByAdressYandex = async () => {
            if (!state.city) return

            if (state.city.latitude && state.city.longitude) {
                setMapCenter([state.city.latitude, state.city.longitude])

                if (state.city.coordinates) {
                    // @ts-ignore
                    toRaw(mapState.instance).options.set('restrictMapArea', state.city.coordinates)
                }

                return
            }

            try {
                const mapsRealisation = new MapsRealisation()
                const response = await mapsRealisation.getYandexCoordsByAddress(state.city.name)

                if (!response) {
                    console.error('Не удалось получить координаты города', state.city.name)
                    return
                }

                setMapCenter(response)
            } catch (error) {
                console.error(error)
            }
        }

        const getClickedAddressGis = async (coords: number[]) => {
            try {
                addressState.is_loading = true

                const mapsRealisation = new MapsRealisation()
                const response = await mapsRealisation.geocodeAddressByGisCoords(coords)

                if (!response) return
                addressState.address = response.full_address
                addressState.street = response.street

                addressState.is_failed = false
                addressState.is_finded = true
            } catch (error) {
                console.error(error)
                addressState.is_failed = true
            } finally {
                addressState.is_loading = false
            }
        }

        const setMapCenter = (coords: number[]) => {
            // @ts-ignore
            mapState.instance.panTo.bind(toRaw(mapState.instance))(coords)
        }

        const mapCreated = (map_instance: any) => {
            mapState.instance = map_instance
            getCoordsByAdressYandex()
        }

        const setMapPlacemark = (coords: number[]) => {
            // @ts-ignore
            mapState.instance.geoObjects.removeAll.bind(toRaw(mapState.instance.geoObjects))()
            // @ts-ignore
            const placemark = new window.ymaps.Placemark(coords, null, {
                preset: isFTL.value ? 'islands#redDotIcon' : 'islands#yellowDotIcon',
            })
            // @ts-ignore
            mapState.instance.geoObjects.add.bind(toRaw(mapState.instance.geoObjects))(placemark)
        }

        const mapClicked = (coords: number[]) => {
            state.latitude = coords[0]
            state.longitude = coords[1]

            addressState.latitude = coords[0]
            addressState.longitude = coords[1]

            setMapPlacemark(coords)
            setMapCenter(coords)

            if (mapState.is_yandex || mapState.is_google) {
                getClickedAdressYandex(coords)
                return
            }

            if (mapState.is_gis) {
                getClickedAddressGis(coords)
                return
            }
        }

        const setFullAddress = () => {
            state.warehouse_id = null
            warehouses_state.is_opened = false

            state.full_address = addressState.address
            state.street = addressState.street

            state.latitude = addressState.latitude
            state.longitude = addressState.longitude

            isMapOpened.value = false
            addressState.address = null
            addressState.is_finded = false
        }

        const mapSwitched = (map_key: string) => {
            if (map_key === MAP_KEYS.yandex) {
                mapState.is_yandex = true
                mapState.is_gis = false
                mapState.is_google = false
            }

            if (map_key === MAP_KEYS.gis) {
                mapState.is_gis = true
                mapState.is_yandex = false
                mapState.is_google = false
            }

            if (map_key === MAP_KEYS.google) {
                mapState.is_yandex = false
                mapState.is_gis = false
                mapState.is_google = true
            }
        }

        const resultFinded = (result: MapSearchResultT) => {
            setMapPlacemark(result.points)
            setMapCenter(result.points)

            addressState.is_finded = true

            addressState.address = result.full_address
            addressState.street = result.street
            addressState.house = result.house

            addressState.latitude = result.points[0]
            addressState.longitude = result.points[1]
        }

        const isMapCreated = computed(() => Boolean(mapState.instance))

        return {
            isFTL,
            isLTL,
            warehouses_state,
            state,
            v$,
            emitConfirm,
            emitClose,
            isCREATE,
            isUPDATE,
            isMapOpened,
            openMap,
            closeMap,
            mapClicked,
            mapCreated,
            addressState,
            senderCities,
            setFullAddress,
            isSenderActionsLoading,
            mapSwitched,
            mapState,
            resultFinded,
            onCityChange,
            onWarehouseToggle,
            isMapCreated,
        }
    },
})


import { defineComponent, PropType } from 'vue'

// components
import SButton from '@/common/components/SButton/index.vue'

// module component
import ExpressMultiTasks from '@/modules/CreateOrder/components/ExpressMultiTasks/index.vue'
import ExpressMultiItemsForm from '@/modules/CreateOrder/components/ExpressMultiItemsForm/index.vue'
import ExpressMultiItems from '@/modules/CreateOrder/components/ExpressMultiItems/index.vue'
import ExpressAdditionalData from '@/modules/CreateOrder/components/ExpressAdditionalData/index.vue'
import ExpressSettedAdditionalData from '@/modules/CreateOrder/components/ExpressSettedAdditionalData/index.vue'

// types
import {
    DeliveryAdditionalDataPayload,
    DeliveryTaskT,
    NewOrderPayloadItem,
    NewOrderPayloadTasks,
} from '@/core/types/Delivery.types'

export default defineComponent({
    components: {
        's-button': SButton,
        'express-multi-tasks': ExpressMultiTasks,
        'express-multi-items-form': ExpressMultiItemsForm,
        'express-multi-items': ExpressMultiItems,
        'express-additional-data': ExpressAdditionalData,
        'express-setted-additional-data': ExpressSettedAdditionalData,
    },
    props: {
        index: {
            type: Number,
            default: 0,
        },
        invoice: {
            type: Object as PropType<any>,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isTasksLoading: {
            type: Boolean,
            default: false,
        },
        isAdditionalData: {
            type: Boolean,
            default: false,
        },
        extraInfo: {
            type: [String, null] as PropType<string | null>,
            default: null,
        },
        verificationType: {
            type: [Number, null] as PropType<number | null>,
            default: null,
        },
        verificationInvoiceNumber: {
            type: [String, null] as PropType<string | null>,
            default: null,
        },
        amountToAcceptPayment: {
            type: [Number, null] as PropType<number | null>,
            default: null,
        },
        isTasksFailed: {
            type: Boolean,
            default: false,
        },
        tasks: {
            type: Array as PropType<DeliveryTaskT[]>,
            default: () => [],
        },
        items: {
            type: Array as PropType<NewOrderPayloadItem[]>,
            default: () => [],
        },
        isTariffSelected: {
            type: Boolean,
            default: false,
        },
        selectedTariffPrice: {
            type: Number,
            default: 0,
        },
    },
    emits: [
        'delete',
        'reload-tasks',
        'select-task',
        'unselect-task',
        'add-item',
        'remove-item',
        'submit-additional-data',
        'reset-additional-data',
    ],
    setup(props, { emit }) {
        const emitDelete = () => {
            if (props.disabled) return

            const answer = confirm(`Вы действительно хотите удалить Накладную №${props.index}?`)
            if (!answer) return

            emit('delete', props.index - 1)
        }

        const emitReloadTasks = () => {
            emit('reload-tasks')
        }

        const emitSelectTask = (task: DeliveryTaskT) => {
            emit('select-task', {
                invoice_index: props.index - 1,
                task: { id: task.id, comment: task.title },
            })
        }

        const emitUnselectTask = (task_id: number) => {
            emit('unselect-task', {
                invoice_index: props.index - 1,
                task_id,
            })
        }

        const emitAddItem = (item: NewOrderPayloadItem) => {
            emit('add-item', {
                invoice_index: props.index - 1,
                item,
            })
        }

        const emitRemoveItem = (item_index: number) => {
            emit('remove-item', {
                invoice_index: props.index - 1,
                item_index,
            })
        }

        const emitSubmitAdditionalData = (payload: DeliveryAdditionalDataPayload) => {
            emit('submit-additional-data', payload)
        }
        const emitResetAdditionalData = (receiver_idx: number) => {
            emit('reset-additional-data', receiver_idx)
        }

        return {
            emitDelete,
            emitReloadTasks,
            emitSelectTask,
            emitUnselectTask,
            emitAddItem,
            emitRemoveItem,
            emitSubmitAdditionalData,
            emitResetAdditionalData,
        }
    },
})

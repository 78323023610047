
import { defineComponent } from 'vue'

import iClose from '@/assets/icons/Close.svg'

export default defineComponent({
    components: {
        'icon-close': iClose
    },
    props: {
        takeDate: {
            type: String,
            required: true
        },
        takeTime: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['reset'],
    setup(props, { emit }) {
        const emitReset = () => {
            emit('reset')
        }

        return { emitReset }
    }
})

import { computed, reactive } from 'vue'

// types
import {
    DeliveryAdditionalDataPayload,
    NewOrderPayload,
    DeliveryTariffT,
    MappedCityT,
    NewOrderPayloadItem,
    NewOrderPayloadReceiver,
    NewOrderPayloadTasks,
} from '@/core/types/Delivery.types'
import { SenderT, ReceiverExpressT } from '@/core/types/common.types'

// constants
const DEFAULT_ERROR_TEXT = 'При загрузке списка тарифов произошла ошибка'
const OUTSIZE_ZONE_FAIL_STATUS = 452
const SPARK_VERIFICATION_ID = 1
const KASPI_VERIFICATION_ID = 2

// composable
import useNotifications from '@/common/composable/useNotifications'

// realisations
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'

export default function () {
    const notifications = useNotifications()
    const delivery = new DeliveryRealisation()

    const state = reactive({
        selected_city: null as MappedCityT | null,
        selected_sender: null as SenderT | null,
        invoices: [] as NewOrderPayloadReceiver[],
        //
        is_creating_order: false,
        is_order_created: false,
    })

    const isCitySelected = computed(() => Boolean(state.selected_city))
    const isSenderSelected = computed(() => Boolean(state.selected_sender))

    const resetState = () => {
        state.selected_city = null
        state.selected_sender = null
        state.invoices = []

        resetTariffsState()

        state.is_creating_order = false
        state.is_order_created = false
    }

    const tariffs_state = reactive({
        is_loading: true,
        is_failed: false,
        tariffs: [] as DeliveryTariffT[],
        cityName: null as string | null,
        distance: null as string | null,
        maxHubCoef: null as string | null,
        error_text: DEFAULT_ERROR_TEXT,
        selected_tariff: null as DeliveryTariffT | null,
    })

    const resetTariffsState = () => {
        tariffs_state.is_loading = false
        tariffs_state.is_failed = false
        tariffs_state.selected_tariff = null
        tariffs_state.tariffs = []
        tariffs_state.cityName = null
        tariffs_state.distance = null
        tariffs_state.maxHubCoef = null
        tariffs_state.error_text = DEFAULT_ERROR_TEXT

        resetTasks()
    }

    const loadTariffs = async () => {
        resetTariffsState()

        try {
            tariffs_state.is_loading = true

            const response = await delivery.getTariffs(coordinates.value)

            tariffs_state.tariffs = response.data.tariffs
            tariffs_state.cityName = response.data.cityName
            tariffs_state.distance = response.data.distance
            tariffs_state.maxHubCoef = response.data.maxHubCoef

            tariffs_state.is_failed = false
        } catch (error) {
            tariffs_state.is_failed = true
            console.log(error)

            // @ts-ignore
            if (error && error.code && error.code === OUTSIZE_ZONE_FAIL_STATUS) {
                // @ts-ignore
                tariffs_state.error_text = error.message
            }

            notifications.failure(
                {
                    group: 'create-module',
                    type: 'error',
                    title: 'Ошибка получения списка тарифов',
                },
                error
            )
        } finally {
            tariffs_state.is_loading = false
        }
    }

    const onCitySelect = (new_city: MappedCityT) => {
        state.selected_sender = null
        state.invoices = []

        resetTariffsState()

        state.selected_city = { ...new_city }
    }

    const onSenderSelect = (new_sender: SenderT) => {
        if (!new_sender.latitude || !new_sender.longitude) return
        state.selected_sender = { ...new_sender }

        if (state.invoices.length > 0) {
            loadTariffs()
        }
    }

    const onSenderReset = () => {
        state.selected_sender = null
        resetTariffsState()
    }

    const onReceiverSelect = (new_receiver: ReceiverExpressT) => {
        if (!new_receiver.latitude || !new_receiver.longitude) return
        const receiver = { ...new_receiver }

        state.invoices.push({
            ...receiver,
            items: [],
            tasks: [],
        })

        if (state.selected_sender) {
            loadTariffs()
        }
    }

    const onInvoiceRemove = (invoice_index: number) => {
        state.invoices.splice(invoice_index, 1)

        if (state.invoices.length === 0) {
            resetTariffsState()
        } else {
            loadTariffs()
        }
    }

    const onSelectTask = ({
        invoice_index,
        task,
    }: {
        invoice_index: number
        task: NewOrderPayloadTasks
    }) => {
        state.invoices[invoice_index].tasks.push(task)
    }

    const onUnselectTask = ({
        invoice_index,
        task_id,
    }: {
        invoice_index: number
        task_id: number
    }) => {
        const task_index = state.invoices[invoice_index].tasks.findIndex(
            (task) => task.id === task_id
        )
        if (task_index < 0) return

        state.invoices[invoice_index].tasks.splice(task_index, 1)
    }

    const onAddItem = ({
        invoice_index,
        item,
    }: {
        invoice_index: number
        item: NewOrderPayloadItem
    }) => {
        state.invoices[invoice_index].items.push(item)
    }

    const onRemoveItem = ({
        invoice_index,
        item_index,
    }: {
        invoice_index: number
        item_index: number
    }) => {
        state.invoices[invoice_index].items.splice(item_index)
    }

    const onSubmitAdditionalData = (payload: DeliveryAdditionalDataPayload) => {
        const receiver = state.invoices[payload.receiver_idx]

        receiver.amount_to_accept_payment = payload.amount_to_accept_payment
        receiver.extra_info = payload.extra_info

        if (payload.is_kaspi_verification) {
            receiver.verification_type = KASPI_VERIFICATION_ID
            receiver.verification_invoice_number = payload.verification_invoice_number
        }

        if (payload.is_spark_verification) {
            receiver.verification_type = SPARK_VERIFICATION_ID
        }

        receiver.verification_invoice_number = payload.verification_invoice_number

        receiver.is_additional_data_setted = true
    }

    const onResetAdditionalData = (receiver_idx: number) => {
        const receiver = state.invoices[receiver_idx]

        receiver.amount_to_accept_payment = null
        receiver.extra_info = null
        receiver.verification_type = null
        receiver.verification_invoice_number = null

        receiver.is_additional_data_setted = false
    }

    const resetTasks = () => {
        state.invoices.forEach((invoice) => {
            invoice.tasks = []
        })
    }

    const onSelectTariff = (new_tariff: DeliveryTariffT) => {
        tariffs_state.selected_tariff = new_tariff
    }

    const selectedTariffId = computed(() => {
        if (!tariffs_state.selected_tariff) return null
        return tariffs_state.selected_tariff.id
    })

    const selectedTariffPrice = computed(() => {
        if (!tariffs_state.selected_tariff) return null
        return tariffs_state.selected_tariff.price
    })

    const coordinates = computed(() => {
        if (state.selected_sender) {
            const sender_coordinates = {
                latitude: state.selected_sender.latitude.toString(),
                longitude: state.selected_sender.longitude.toString(),
            }

            if (state.invoices.length === 0) return [sender_coordinates]

            const invoices_coordinates = state.invoices.map((invoice) => {
                return {
                    latitude: invoice.latitude.toString(),
                    longitude: invoice.longitude.toString(),
                }
            })

            return [sender_coordinates, ...invoices_coordinates]
        }

        return []
    })

    const isActionsDisabled = computed(() => state.is_creating_order || state.is_order_created)

    const notifyValidationError = (title: string) => {
        notifications.success({
            group: 'create-module',
            type: 'error',
            title,
        })
    }

    const getPayload = (): NewOrderPayload => {
        const selected_tariff = tariffs_state.selected_tariff as DeliveryTariffT

        console.log(state.invoices)

        const payload: NewOrderPayload = {
            product_name: '',
            city_id: (state.selected_city as MappedCityT).id,
            transport_type: selected_tariff.transport,
            sender: { ...(state.selected_sender as SenderT) },
            receivers: { ...state.invoices },
            origin: 1,
            is_fastest: selected_tariff.isFastest,
        }

        return payload
    }

    const createOrder = async () => {
        if (state.is_creating_order || state.is_order_created) return

        if (!state.selected_sender) {
            notifyValidationError('Пожалуйста, выберите отправителя')
            return
        }

        if (!state.invoices.length) {
            notifyValidationError('Пожалуйста, выберите хотя бы 1 получателя')
            return
        }

        if (!tariffs_state.selected_tariff) {
            notifyValidationError('Пожалуйста, выберите тариф')
            return
        }

        try {
            state.is_creating_order = true

            const payload = getPayload()
            await delivery.createExpressOrder(payload)

            notifications.success({
                group: 'create-module',
                type: 'success',
                title: 'Заказ создан',
            })

            state.is_order_created = true
        } catch (error) {
            console.error(error)
            notifications.failure(
                {
                    group: 'create-module',
                    type: 'error',
                    title: 'При создании заказа произошла ошибка',
                },
                error
            )
        } finally {
            state.is_creating_order = false
        }
    }

    return {
        state,
        resetState,
        tariffs_state,
        loadTariffs,
        onCitySelect,
        onSenderSelect,
        onSenderReset,
        onReceiverSelect,
        onInvoiceRemove,
        onSelectTask,
        onUnselectTask,
        onAddItem,
        onRemoveItem,
        onSubmitAdditionalData,
        onResetAdditionalData,
        onSelectTariff,
        createOrder,
        //
        isCitySelected,
        isSenderSelected,
        coordinates,
        isActionsDisabled,
        selectedTariffId,
        selectedTariffPrice,
    }
}

import { computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import moment from 'moment'

// realisations
import { FTLRealisation } from '@/core/realisations/FTL.realisation'

// types
import { NewWaypointT } from '@/core/types/FTL.types'
import { AdditionalServicesT } from '@/core/types/common.types'

import useNotifications from '@/common/composable/useNotifications'
import { useFormData } from '@/common/composable/useFormData'

import { EMPTY_ADDITIONAL_SERVICES } from '@/core/constants/common.constants'
const NOTIFICATIONS_GROUP = 'create-module'

export default function () {
    const notifications = useNotifications()
    const ftlRealisation = new FTLRealisation()
    const { getFormData } = useFormData()

    const state = reactive({
        take_date: null as string | null,
        take_time: null as string | null,
        description: null as string | null,
        weight: null as number | null,
        height: null as number | null,
        width: null as number | null,
        depth: null as number | null,
        places: null as number | null,
        is_dangerous: 0,
        is_need_car: 0,
        is_stacked: 0,
        payment_method: 3,
        shipment_type: 1,
        ftl_type: 0,
        car_info: {
            cubature: null as string | null,
            temperature_mode: null as string | null,
            loading_type: null as string | null,
            oversized_cargo: 0,
            load_capacity: null as string | null,
        },
        danger_class: null as number | null,
        insurance: null as string | null,
        declared_price: null as number | null,
        cargo_name: null as string | null,
        hs_code: null as string | null,
        packaging: null as string | null,
        special_conditions: null as string | null,
        customs_clearance: null as number | null,
        client_price: null as number | null,
        transport_type: null as number | null,
        dop_order_number: null as string | null,
        invoice_files: [] as File[],
        certificate_of_safety_files: [] as File[],
        waypoints: [] as NewWaypointT[],
    })

    const additional_services_state = reactive({
        is_additional_services_setted: false,
    })

    const progress = reactive({
        is_loading: false,
        is_failed: false,
        is_created: false,
        is_cargo_setted: false,
    })

    const router = useRouter()

    const isAuto = computed(() => {
        return state.shipment_type === 1
    })

    const createBID = async () => {
        if (
            !state.take_date ||
            !state.take_time ||
            (!state.description && state.is_need_car) ||
            (((!state.car_info.cubature && state.ftl_type !== 1) ||
                (!state.car_info.load_capacity && state.ftl_type !== 1) ||
                (!state.car_info.loading_type && state.ftl_type !== 1)) &&
                isAuto.value &&
                state.is_need_car)
        ) {
            return
        }

        try {
            progress.is_loading = true

            await ftlRealisation.createBID(getFormData(state))

            notifications.success({
                group: NOTIFICATIONS_GROUP,
                type: 'success',
                title: 'Заявка создана',
            })

            progress.is_failed = false
            progress.is_created = true

            await router.push('/history')
        } catch (error) {
            progress.is_failed = true
            progress.is_created = false

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка создания FTL заявки',
                },
                error
            )

            console.error(error)
        } finally {
            progress.is_loading = false
        }
    }

    const setDateAndTime = ({ send_date, send_time }: { send_date: Date; send_time: string }) => {
        state.take_date = moment(send_date).format('YYYY-MM-DD')
        state.take_time = send_time
    }

    const resetDateAndTime = () => {
        state.take_date = null
        state.take_time = null
    }

    const setAdditionalServices = (additional_services: AdditionalServicesT, element: any) => {
        element.additional_service = { ...EMPTY_ADDITIONAL_SERVICES }

        element.additional_service.hasCar = additional_services.hasCar
        element.additional_service.hasCrane = additional_services.hasCrane
        element.additional_service.hasGrid = additional_services.hasGrid
        element.additional_service.hasHydraulicTrolley = additional_services.hasHydraulicTrolley
        element.additional_service.hasLoader = additional_services.hasLoader
        element.additional_service.hasManipulator = additional_services.hasManipulator
        element.additional_service.hasPallet = additional_services.hasPallet
        element.additional_service.hasSoftPackage = additional_services.hasSoftPackage

        element.is_additional_services_setted = true
    }

    const resetAdditionalServices = (element: any) => {
        element.additional_service.hasCar = false
        element.additional_service.hasCrane = false
        element.additional_service.hasGrid = false
        element.additional_service.hasHydraulicTrolley = false
        element.additional_service.hasLoader = false
        element.additional_service.hasManipulator = false
        element.additional_service.hasPallet = false
        element.additional_service.hasSoftPackage = false

        element.is_additional_services_setted = false
    }

    const setCharacteristics = (characteristics: any) => {
        state.description = characteristics.description
        state.weight = characteristics.weight
        state.height = characteristics.height
        state.width = characteristics.width
        state.depth = characteristics.depth
        state.places = characteristics.places
        state.is_dangerous = characteristics.is_dangerous
        state.is_need_car = characteristics.is_need_car
        state.is_stacked = characteristics.is_stacked
        state.payment_method = characteristics.payment_method
        state.shipment_type = characteristics.shipment_type
        state.danger_class = characteristics.danger_class
        state.insurance = characteristics.insurance
        state.declared_price = characteristics.declared_price
        state.cargo_name = characteristics.cargo_name
        state.hs_code = characteristics.hs_code
        state.packaging = characteristics.packaging
        state.special_conditions = characteristics.special_conditions
        state.customs_clearance = characteristics.customs_clearance
        state.client_price = characteristics.client_price
        state.transport_type = characteristics.transport_type

        state.car_info.cubature = characteristics.car_info.cubature
        state.car_info.load_capacity = characteristics.car_info.load_capacity
        state.car_info.loading_type = characteristics.car_info.loading_type
        state.car_info.oversized_cargo = characteristics.car_info.oversized_cargo
        state.car_info.temperature_mode = characteristics.car_info.temperature_mode

        state.dop_order_number = characteristics.dop_order_number

        state.invoice_files = characteristics.invoice_files
        state.certificate_of_safety_files = characteristics.certificate_of_safety_files

        progress.is_cargo_setted = true
    }

    const resetCharacteristics = () => {
        state.description = null
        state.weight = null
        state.height = null
        state.width = null
        state.depth = null
        state.places = null
        state.is_dangerous = 0
        state.is_need_car = 0
        state.is_stacked = 0
        state.payment_method = 3
        state.shipment_type = 1
        state.danger_class = null
        state.insurance = null
        state.declared_price = null
        state.cargo_name = null
        state.hs_code = null
        state.packaging = null
        state.special_conditions = null
        state.customs_clearance = 0
        state.client_price = null
        state.transport_type = null

        state.car_info.cubature = null
        state.car_info.load_capacity = null
        state.car_info.loading_type = null
        state.car_info.oversized_cargo = 0
        state.car_info.temperature_mode = null

        state.invoice_files = []
        state.certificate_of_safety_files = []

        progress.is_cargo_setted = false
    }

    const setWaypoints = (waypoints: NewWaypointT[]) => {
        state.waypoints = waypoints
    }

    const onTypeChange = () => {
        state.waypoints = []
    }

    return {
        state,
        additional_services_state,
        progress,
        createBID,
        setDateAndTime,
        resetDateAndTime,
        setCharacteristics,
        resetCharacteristics,
        setWaypoints,
        onTypeChange,
        setAdditionalServices,
        resetAdditionalServices,
    }
}


import { defineComponent, PropType } from 'vue'

import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'
import { DeliveryTaskT, NewOrderPayloadTasks } from '@/core/types/Delivery.types'

export default defineComponent({
    components: {
        's-button': SButton,
        's-loader': SLoader,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        tasks: {
            type: Array as PropType<DeliveryTaskT[]>,
            default: () => [],
        },
        selectedTasksIds: {
            type: Array as PropType<number[]>,
            default: () => [],
        },
        isTariffSelected: {
            type: Boolean,
            default: false,
        },
        selectedTariffPrice: {
            type: Number,
            default: 0,
        },
    },
    emits: ['reload', 'unselect', 'select'],
    setup(props, { emit }) {
        const emitReload = () => {
            if (props.disabled) return
            emit('reload')
        }

        const emitUnselect = (task_id: number) => {
            if (props.disabled) return
            emit('unselect', task_id)
        }

        const emitSelect = (task: DeliveryTaskT) => {
            if (props.disabled) return
            emit('select', task)
        }

        return { emitReload, emitUnselect, emitSelect }
    },
})
